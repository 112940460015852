import { useState, useEffect } from 'react';

import { MarqueDto } from 'lib_api/lib/api/gen';

import { useApi } from '../ApiStoreContext';
import { useHandleBackErrors } from '../utils/handleBackErrors';

export const useFetchMarque = (marqueId: string | null): MarqueDto | null => {
  const behaviourOnError = useHandleBackErrors();
  const [marque, setMarque] = useState<MarqueDto | null>(null);
  const referentielControllerApi = useApi().ReferentielControllerApi;

  useEffect(() => {
    if (marqueId) {
      referentielControllerApi
        .getMarqueByIdUsingGET(marqueId)
        .then(setMarque)
        .catch(behaviourOnError);
    }
  }, [referentielControllerApi, behaviourOnError]);
  return marque;
};
