import React, { useMemo, useState } from 'react';

import {
  ComputedResponseDtoListAvailableFilesEnum,
  DossierDtoAdminFonctionnelActionsDisponiblesEnum,
  DossierDtoAdminFonctionnelListAvailableFilesEnum,
} from 'lib_api/lib/api/gen';

import { useDownloadDossierAdminFonctionnel } from 'hooks/dossiers/useDownloadDossierAdminFonctionnel';
import { useDossierAdminContext } from 'hooks/dossierAdmin/DossierAdminContext';
import { useModal } from 'hooks/modal/useModal';
import ActionAndFileWrapper from 'components/ActionAndFileWrapper/ActionAndFileWrapper';
import { AvailableAction } from 'components/ActionAndFileWrapper/types';
import BaseModal from 'components/WrappedComponents/BaseModal/BaseModal';
import { ModalConfirmationAnnulationSortie } from './ModalConfirmationAnnulationSortie';
import { ModalSuppressionDossier } from './ModalSuppressionDossierProps';
import UpdateEntiteRatachement from './UpdateEntitesRattachements/UpdateEntiteRatachement';
import UpdateSortie from './UpdateSortie/UpdateSortie';
import UpdateClassement from './UpdateClassement/UpdateClassement';
import UpdateDonneesVehicule from './UpdateDonneesVehicule/UpdateDonneesVehicule';
import FixDataNotificationManuelle from './FixDataNotificationManuelle/FixDataNotificationManuelle';

import './ActionsSuiviVehicule.less';

type ActionMapping = {
  [key in DossierDtoAdminFonctionnelActionsDisponiblesEnum]?: AvailableAction;
};

const getValueOrEmptyList = <T,>(value?: T): T[] => (value ? [value] : []);

const ActionsSuiviVehicule = (): React.ReactElement => {
  const [, setIsDownloading] = useState<boolean>(false);
  const [{ result: dossierAdmin }] = useDossierAdminContext();
  const download = useDownloadDossierAdminFonctionnel(
    setIsDownloading,
    dossierAdmin.id,
    dossierAdmin.immatriculation,
  );

  const updateEntiteRattachementModal = useModal();
  const cancelSortieModal = useModal();
  const deleteDossierModal = useModal();
  const updateDonneesVehicule = useModal();
  const updateSortieModal = useModal();
  const updateClassementModal = useModal();
  const fixDataNotificationManuelle = useModal();

  const actionMapping: ActionMapping = useMemo(() => {
    return {
      EXPORT_DONNEES_DOSSIER: {
        title: 'Exporter les données du dossier',
        onClick: () => download(),
      },
      UPDATE_ENTITES_RATTACHEMENT: {
        title: 'Modifier les entités de rattachement',
        onClick: updateEntiteRattachementModal.open,
      },
      ANNULATION_SORTIE_VEHICULE: {
        title: 'Annuler la sortie',
        onClick: cancelSortieModal.open,
      },
      MODIFICATION_SORTIE_VEHICULE: {
        title: 'Modifier les données de sortie',
        onClick: updateSortieModal.open,
      },
      SUPPRESSION_DOSSIER: {
        title: 'Supprimer le dossier',
        onClick: deleteDossierModal.open,
      },
      UPDATE_CLASSEMENT: {
        title: 'Modifier le classement',
        onClick: updateClassementModal.open,
      },
      UPDATE_DONNEES_VEHICULE: {
        title: "Modifier les données du véhicule et de l'infraction",
        onClick: updateDonneesVehicule.open,
      },
      CORRIGER_DONNEES_NOTIFICATION_MANUELLE: {
        title: 'Modifier le suivi de notification',
        onClick: fixDataNotificationManuelle.open,
      },
    };
  }, []);

  const actions: AvailableAction[] =
    dossierAdmin.actionsDisponibles?.flatMap(action =>
      getValueOrEmptyList(actionMapping[action]),
    ) ?? [];

  const availableFiles: ComputedResponseDtoListAvailableFilesEnum[] =
    dossierAdmin.listAvailableFiles?.map(
      value => ComputedResponseDtoListAvailableFilesEnum[value],
    ) ?? [];

  return (
    <>
      <ActionAndFileWrapper
        filesList={availableFiles}
        actions={actions}
        dossierId={dossierAdmin.id}
      />
      <BaseModal
        width={700}
        isVisible={updateEntiteRattachementModal.visible}
        closeModal={updateEntiteRattachementModal.close}
      >
        <UpdateEntiteRatachement
          closeModal={updateEntiteRattachementModal.close}
        />
      </BaseModal>
      <BaseModal
        width={700}
        isVisible={updateSortieModal.visible}
        closeModal={updateSortieModal.close}
      >
        <UpdateSortie closeModal={updateSortieModal.close} />
      </BaseModal>
      <BaseModal
        width={700}
        isVisible={updateDonneesVehicule.visible}
        closeModal={updateDonneesVehicule.close}
      >
        <UpdateDonneesVehicule closeModal={updateDonneesVehicule.close} />
      </BaseModal>
      <BaseModal
        width={700}
        isVisible={updateClassementModal.visible}
        closeModal={updateClassementModal.close}
      >
        <UpdateClassement closeModal={updateClassementModal.close} />
      </BaseModal>
      <BaseModal
        width={700}
        isVisible={fixDataNotificationManuelle.visible}
        closeModal={fixDataNotificationManuelle.close}
      >
        <FixDataNotificationManuelle
          closeModal={fixDataNotificationManuelle.close}
        />
      </BaseModal>
      <ModalSuppressionDossier
        visible={deleteDossierModal.visible}
        close={deleteDossierModal.close}
        dossierId={dossierAdmin.id}
      />
      <ModalConfirmationAnnulationSortie
        visible={cancelSortieModal.visible}
        close={cancelSortieModal.close}
        dossierId={dossierAdmin.id}
      />
    </>
  );
};

export default ActionsSuiviVehicule;
