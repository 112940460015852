import React from 'react';
import { Checkbox as AntdCheckbox } from 'antd';

import {
  AutocompleteUniteFOResultDtoFieldEnum,
  TraitementResponseDtoClassementEnum,
  UniteFOResponseDto,
} from 'lib_api/lib/api/gen';

import BaseForm from 'components/BaseForm/BaseForm';
import BaseSelect from 'components/BaseForm/Select/BaseSelect';
import BaseSelectWithSearch from 'components/BaseForm/Select/BaseSelectWithSearch';
import BaseRangeDatePicker from 'components/BaseForm/BaseRangeDatePicker';
import AsyncSelect from 'components/BaseForm/Select/AsyncSelect';
import { useAsyncSearchUniteFo } from 'hooks/search/async/useAsyncSearchUniteFo';
import { StatutTraitementFacturation } from 'hooks/dossiers/useSearchDossierSummaries';
import useAsyncSearchFourriere from 'hooks/search/async/useAsyncSearchFourriere';
import { useApi } from 'hooks/ApiStoreContext';
import { DashboardAdminFonctionnelFilterFormValues } from 'pages/admin-fonctionnel/Dashboard/types';
import { fetchBranchesList } from 'search/searchBranche';
import { fetchMotifSortie } from 'search/searchMotifSortie';
import { fetchStatutsConsultables } from 'search/searchStatutsDossier';
import {
  classementEnumMapping,
  statutEnumMapping,
  statutFacturationEnumMapping,
} from 'utils/enumData';
import { CSSUtils } from 'utils/CSSUtils';
import { FormFooter } from './FormFooter';

interface DashboardAdminFonctionnelFilterFormProps {
  filter: Partial<DashboardAdminFonctionnelFilterFormValues>;
  setFilter: (
    value: Partial<DashboardAdminFonctionnelFilterFormValues>,
  ) => void;
}

function DashboardAdminFonctionnelFilterForm({
  filter,
  setFilter,
}: DashboardAdminFonctionnelFilterFormProps): React.ReactElement {
  const { ReferentielControllerApi: refController } = useApi();

  return (
    <BaseForm
      initialValues={filter}
      subForms={[
        {
          title: 'Général',
          inputs: [
            {
              name: 'statut',
              label: 'Statut',
              render: () => (
                <AsyncSelect
                  fetchOptions={() => {
                    return fetchStatutsConsultables(refController);
                  }}
                  getOptionValue={option => option.toString()}
                  getOptionLabel={option => statutEnumMapping[option].label}
                  mode="multiple"
                />
              ),
            },
            {
              name: 'classement',
              label: 'Classement',
              render: () => (
                <BaseSelect
                  options={Object.values(TraitementResponseDtoClassementEnum)}
                  getOptionValue={option => option.toString()}
                  getOptionLabel={option => classementEnumMapping[option].label}
                  mode="multiple"
                />
              ),
            },
            {
              name: 'fourriere',
              label: 'Fourrière',
              render: () => (
                <BaseSelectWithSearch
                  useSearchEntities={() => useAsyncSearchFourriere()}
                  getOptionValue={option => option.value}
                  getOptionLabel={option => option.displayValue}
                  mode="multiple"
                  allowClear
                />
              ),
            },
            {
              name: 'idUniteFOList',
              label: 'Unités FO',
              render: () => (
                <BaseSelectWithSearch
                  useSearchEntities={useAsyncSearchUniteFo(
                    AutocompleteUniteFOResultDtoFieldEnum.ABREVIATION,
                  )}
                  getOptionLabel={(unite: UniteFOResponseDto) =>
                    unite.abreviation
                  }
                  getOptionValue={(unite: UniteFOResponseDto) =>
                    unite.idCorrelation
                  }
                  mode="multiple"
                  allowClear
                />
              ),
            },
            {
              name: 'statutsTraitementFacturation',
              label: 'Etat de facturation',
              render: () => (
                <BaseSelect
                  options={[
                    StatutTraitementFacturation.TRAITE,
                    StatutTraitementFacturation.NON_TRAITE,
                  ]}
                  getOptionValue={option => option.toString()}
                  getOptionLabel={option =>
                    statutFacturationEnumMapping[option].label
                  }
                  mode="multiple"
                  allowClear
                />
              ),
            },
            {
              name: 'includeDossiersAnonymises',
              label: 'Afficher les véhicules expirés :',
              valuePropName: 'checked',
              render: () => <AntdCheckbox />,
            },
          ],
        },
        {
          title: 'Mise en fourrière',
          inputs: [
            {
              name: 'natureMiseEnFourriere',
              label: 'Nature de mise en fourrière',
              render: () => (
                <AsyncSelect
                  fetchOptions={() => {
                    return fetchBranchesList(refController);
                  }}
                  getOptionValue={option => option.id}
                  getOptionLabel={option => option.nature}
                  mode="multiple"
                />
              ),
            },
            {
              name: 'dateRangeMiseEnFourriere',
              label: 'Date de mise en fourrière',
              render: () => <BaseRangeDatePicker />,
            },
          ],
        },
        {
          title: 'Suivi de mise en fourrière',
          inputs: [
            {
              name: 'dateRangeOrdreDestruction',
              label: "Date d'ordre de destruction",
              render: () => <BaseRangeDatePicker />,
            },
            {
              name: 'dateRangeBonEnlevement',
              label: "Date du bon d'enlèvement",
              render: () => <BaseRangeDatePicker />,
            },
            {
              name: 'dateRangeSortieDefinitive',
              label: 'Date de la sortie définitive',
              render: () => <BaseRangeDatePicker />,
            },
            {
              name: 'motifDeSortie',
              label: 'Motif de sortie',
              render: () => (
                <AsyncSelect
                  fetchOptions={() => {
                    return fetchMotifSortie(refController);
                  }}
                  getOptionValue={option => option.idCorrelation}
                  getOptionLabel={option => option.motif}
                  mode="multiple"
                />
              ),
            },
          ],
        },
      ]}
      onSubmit={() => Promise.resolve()}
      renderButtonAction={() => <FormFooter setFilter={setFilter} />}
      wrapperCol={{ span: CSSUtils.LlargeWrapperCol }}
    />
  );
}

export default DashboardAdminFonctionnelFilterForm;
