import React, { useState } from 'react';
import 'antd/dist/antd.css';
import AntdForm from 'antd/lib/form';

import { FormWrapperProps, SubmitButtonConf, FormErrors } from './type';
import {
  createSubmitButton,
  usePromiseTrigger,
  generateSubmitFunction,
} from './utils';
import FormActionsProvider from './FormActionsContext';

//  Component to wrap Antd form component and implement basic logic for submit button and store our form values
function FormWrapper<T>({
  className,
  initialValue,
  id,
  validateMessages,
  validateButtonTitle,
  validateButtonClassName,
  onSubmit,
  onChange,
  customizedPopoverError,
  children,
  form,
}: FormWrapperProps<T>): React.ReactElement {
  const [pForm] = AntdForm.useForm(form);

  // saving initial values to be able to reset fields
  const [initialValues] = useState<T>(initialValue);
  //  values in form, init with initialValues and updated when a field changes
  const values = React.useRef<T>(initialValue);

  const [submitPromise, setSubmitPromise] = usePromiseTrigger();

  return (
    <AntdForm
      form={pForm}
      className={className}
      name={id}
      scrollToFirstError={true}
      initialValues={initialValues}
      onValuesChange={(changedValues, allValues): void => {
        values.current = allValues as T;
        const castedChangedValues = changedValues as Partial<T>;

        onChange && onChange(values.current, castedChangedValues);
      }}
      validateMessages={validateMessages}
    >
      {(): React.ReactElement => {
        //  check for field validation and field with error in the form
        let hasError = false;
        let errors: FormErrors<T> = {};
        for (const key of Object.keys(values.current)) {
          const fielError = pForm.getFieldError(key);
          if (fielError !== undefined && fielError.length > 0) {
            hasError = true;
            errors = {
              ...errors,
              key: fielError,
            };
          }
        }

        const defaultPopOver = hasError
          ? {
              content:
                'Le formulaire comporte des erreurs. Corrigez-les pour continuer.',
            }
          : undefined;

        //  generate the submit function that will call setState safely in the composant
        const submitForm = generateSubmitFunction(
          setSubmitPromise,
          onSubmit,
          values,
          pForm,
        );
        const conf: SubmitButtonConf = {
          submit: submitForm,
          isSubmiting: submitPromise !== null,
          hasError: hasError,
          popOverConf: customizedPopoverError
            ? customizedPopoverError(values.current, errors)
            : defaultPopOver,
          title: validateButtonTitle,
          className: validateButtonClassName,
        };
        return (
          <FormActionsProvider value={pForm}>
            {children(values.current, createSubmitButton(conf), conf)}
          </FormActionsProvider>
        );
      }}
    </AntdForm>
  );
}

export default FormWrapper;
