import React from 'react';
import { Link } from 'react-router-dom';
import {
  Badge as AntdBadge,
  Divider as AntdDivider,
  Tooltip as AntdTooltip,
} from 'antd';
import { ExclamationCircleTwoTone as AntdWarningIcon } from '@ant-design/icons';
import { gold } from '@ant-design/colors';
import {
  ActionsRequisesDtoActionsRequisesEnum,
  ComputedSummaryResponseDtoListActionsRequisesEnum,
  DossierSummaryResponseDto,
} from 'lib_api/lib/api/gen';

import './ActionsRequises.less';

function buildActionsRequisesTooltipTitle(
  actionsRequises: ComputedSummaryResponseDtoListActionsRequisesEnum[],
): React.ReactNode {
  return (
    // antd tooltip's overlay style does not seem to work with text
    // https://github.com/ant-design/ant-design/issues/23002
    <div className="actionsRequisesTooltipTitle">
      {actionsRequises
        .map(actionRequise => {
          switch (actionRequise) {
            case ComputedSummaryResponseDtoListActionsRequisesEnum.CLASSER_MANUELLEMENT:
              return buildAction(
                "Le classement n'a pas pu être effectué automatiquement.",
                'Cliquer ici pour compléter les informations techniques du véhicule.',
              );
            case ComputedSummaryResponseDtoListActionsRequisesEnum.SAISIE_NOTIFICATION_MANUELLE:
              return buildAction(
                'Cliquer ici pour éditer la notification.',
                "Penser à renseigner la date d'envoi de la notification.",
              );
            case ComputedSummaryResponseDtoListActionsRequisesEnum.SAISIE_VHU:
              return buildAction(
                'Le véhicule est à détruire.',
                "Cliquer ici pour renseigner le centre VHU puis télécharger le bon d'enlèvement.",
              );
            case ComputedSummaryResponseDtoListActionsRequisesEnum.MODIFIER_COORDONNEES_PROPRIETAIRE:
              return buildAction(
                'Les coordonnées du propriétaire ne sont pas suffisantes pour le notifier.',
                'Cliquer ici pour compléter les coordonnées.',
              );
            case ComputedSummaryResponseDtoListActionsRequisesEnum.RENSEIGNER_STATUT_RETOUR_NOTIFICATION_MANUELLE:
              return buildAction(
                'La notification a été envoyée il y a 20 jours.',
                'Cliquer ici pour renseigner la date et le statut de retour de notification.',
              );
            case ComputedSummaryResponseDtoListActionsRequisesEnum.PROLONGER_MEF:
              return buildAction(
                'Si une prolongation de mise en fourrière administrative a été prescrite par le juge,',
                'cliquer ici pour la renseigner.',
              );
            case ComputedSummaryResponseDtoListActionsRequisesEnum.ENREGISTRER_ENTREE:
              return buildAction(
                'Le dossier doit être complété pour finaliser l’enregistrement du véhicule.',
                'Cliquer ici pour remplir le dossier.',
              );
            case ComputedSummaryResponseDtoListActionsRequisesEnum.CONTROLER_DONNEES_VEHICULE:
              return buildAction(
                'Un doute a été émis sur la fiabilité des données du véhicule.',
                'Cliquer ici pour les contrôler.',
              );
            default:
              return null;
          }
        })
        .reduce((first, second) => [
          first,
          <AntdDivider key={Math.random()} style={{ borderColor: 'gray' }} />,
          second,
        ])}
    </div>
  );
}

function buildAction(
  libelle: string,
  libelleClickHere: string,
): React.ReactNode {
  return (
    <>
      <p>{libelle}</p>
      <p className="actionsRequisesClickHere">{libelleClickHere}</p>
    </>
  );
}

export interface ActionsRequisesLinkState {
  openClassementManuel?: boolean;
  openCoordonneesProprietaire?: boolean;
  openSaisieVhu?: boolean;
  openSuiviNotification?: boolean;
  openProlongationMef?: boolean;
  openEntreeVehicule?: boolean;
  openControlerDonnees?: boolean;
}

function buildActionsRequisesLinkState(
  actionsRequises: ComputedSummaryResponseDtoListActionsRequisesEnum[],
): ActionsRequisesLinkState {
  if (actionsRequises.length > 1) {
    // do not open any modal by default if there are more than one to be opened
    return {};
  }

  return {
    openClassementManuel:
      actionsRequises[0] ===
      ComputedSummaryResponseDtoListActionsRequisesEnum.CLASSER_MANUELLEMENT,
    openCoordonneesProprietaire:
      actionsRequises[0] ===
      ComputedSummaryResponseDtoListActionsRequisesEnum.MODIFIER_COORDONNEES_PROPRIETAIRE,
    openSaisieVhu:
      actionsRequises[0] ===
      ComputedSummaryResponseDtoListActionsRequisesEnum.SAISIE_VHU,
    openSuiviNotification:
      actionsRequises[0] ===
      ComputedSummaryResponseDtoListActionsRequisesEnum.RENSEIGNER_STATUT_RETOUR_NOTIFICATION_MANUELLE,
    openProlongationMef:
      actionsRequises[0] ===
      ComputedSummaryResponseDtoListActionsRequisesEnum.PROLONGER_MEF,
    openEntreeVehicule:
      actionsRequises[0] ===
      ComputedSummaryResponseDtoListActionsRequisesEnum.ENREGISTRER_ENTREE,
    openControlerDonnees:
      actionsRequises[0] ===
      ComputedSummaryResponseDtoListActionsRequisesEnum.CONTROLER_DONNEES_VEHICULE,
  };
}

export function buildActionsRequisesContent(
  url: string,
  data: DossierSummaryResponseDto,
  profileActionsRequises: ActionsRequisesDtoActionsRequisesEnum[] | undefined,
): React.ReactNode {
  const antdWarningColor = gold.primary;
  const actionsRequises = data.computed?.listActionsRequises?.filter(
    actionRequise =>
      profileActionsRequises?.includes(
        ActionsRequisesDtoActionsRequisesEnum[actionRequise],
      ),
  );
  if (!!actionsRequises && actionsRequises.length > 0) {
    return (
      <Link
        to={`${url}/dossier/${data.id}`}
        state={buildActionsRequisesLinkState(actionsRequises)}
        className="actionsRequisesLink"
      >
        <AntdTooltip
          title={buildActionsRequisesTooltipTitle(actionsRequises)}
          overlayStyle={{ cursor: 'pointer' }}
        >
          <AntdBadge
            count={
              // do not display the badge if only one action is required
              actionsRequises.length > 1 ? actionsRequises.length : 0
            }
            size={'small'}
          >
            <AntdWarningIcon
              twoToneColor={antdWarningColor}
              className="actionsRequisesIcon"
            />
          </AntdBadge>
        </AntdTooltip>
      </Link>
    );
  }
  return null;
}
