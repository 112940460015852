import React from 'react';

import {
  AutoriteFourriereAutocompleteDepartementResultDto,
  AutoriteFourriereAutocompleteDepartementResultDtoFieldEnum,
} from 'lib_api/lib/api/gen';

import useAsyncSearch from 'hooks/search/async/useAsyncSearch';
import { SearchResult } from 'types/searchResult';
import useSearchDepartementAf from '../useSearchDepartementAf';
import { ShouldNotHappen } from 'utils';

export function useAsyncSearchDepartementAf(
  field: AutoriteFourriereAutocompleteDepartementResultDtoFieldEnum,
): (defaultValue?: string) => SearchResult {
  return (defaultValue?: string) => {
    const mapResult = (
      result: AutoriteFourriereAutocompleteDepartementResultDto,
    ) => {
      return result.listResults.map(departement => {
        switch (field) {
          case AutoriteFourriereAutocompleteDepartementResultDtoFieldEnum.CODE_DEPARTEMENT:
            return {
              value: departement.code ?? '',
              displayValue: departement.code ?? '',
            };
          case AutoriteFourriereAutocompleteDepartementResultDtoFieldEnum.LIBELLE_DEPARTEMENT:
            return {
              value: departement.associatedLibelle ?? '',
              displayValue: departement.associatedLibelle ?? '',
            };
          default:
            throw new ShouldNotHappen(field);
        }
      });
    };
    const updateFilters = (query?: string) => {
      return { search: query, field: field };
    };
    const defaultFilter = { search: defaultValue, field: field };

    return useAsyncSearch(
      useSearchDepartementAf,
      mapResult,
      updateFilters,
      defaultFilter,
    );
  };
}
