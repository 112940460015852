import { useCallback, useEffect, useState } from 'react';

export function useSearchWithPagination<Type, Filtre, Response>(
  fetch: (filters: Filtre) => Promise<Response>,
  initialFilters: Filtre,
  mapResult: (response: Response) => Type[],
): {
  inProgress: boolean;
  options: Type[];
  search: (query: string) => void;
  pagination: (page: number | undefined) => void;
  filters: Filtre;
  setFilters: (filters: Filtre) => void;
  fetchOptions: (isFirstPage: boolean) => void;
} {
  const [options, setOptions] = useState<Type[]>([]);
  const [inProgress, setInProgress] = useState<boolean>(false);
  const [query, setQuery] = useState<string | undefined>(undefined);
  const [filters, setFilters] = useState<Filtre>(initialFilters);

  const fetchOptions = useCallback(
    isFirstPage => {
      setInProgress(true);
      fetch(filters)
        .then(response => {
          const result = mapResult(response);
          setOptions(isFirstPage ? result : [...options, ...result]);
        })
        .finally(() => {
          setInProgress(false);
        });
    },
    [fetch, options, filters, mapResult, setOptions, setInProgress],
  );

  useEffect(() => {
    if (query === undefined) {
      return;
    }
    const delayedFunction = setTimeout(() => {
      setFilters({
        ...filters,
        libelle: query,
        page: 1,
      });
    }, 500);

    return () => {
      clearTimeout(delayedFunction);
    };
  }, [query]);

  const search = (value: string) => {
    setQuery(value);
  };

  const pagination = (page?: number) => {
    setFilters({
      ...filters,
      page: page ? page + 1 : 1,
    });
  };

  return {
    inProgress,
    options,
    search,
    pagination,
    filters,
    setFilters,
    fetchOptions,
  };
}
