// Utils mappings to get various data associated to enum values (especially
// labels corresponding to each enum). Since a unique enum in backend may
// result in multiple enums in generated clients, those mappings may accept
// multiple enums as parameters.
import {
  AutoritePrescriptriceDtoQualiteAutoriteEnum,
  ComputedResponseDtoListAvailableFilesEnum,
  EtatDtoEtatEnum,
  FicheInfractionRequestDtoQualiteAutoriteEnum,
  FicheVehiculeRequestDtoEtatLibelleEnum,
  MainLeveeDtoGradeAgentEnum,
  MainLeveeDtoTypeRestitutionEnum,
  MarqueDtoCategorieEnum,
  MarqueDtoTypeEnum,
  ReferentielEnumDtoImportReferentielEnumCSVEnum,
  ReferentielEnumDtoReferentialEnumCSVEnum,
  ReferentielEnumDtoReferentialEnumJSONEnum,
  TraitementResponseDtoClassementEnum,
  TraitementResponseDtoListStatutEnum,
  TraitementSummaryDtoClassementEnum,
  UpdateMainLeveeRequestDtoGradeAgentEnum,
  UpdateMainLeveeRequestDtoTypeRestitutionEnum,
  UserDtoProfileEnum,
} from 'lib_api/lib/api/gen';
import { StatutTraitementFacturation } from '../hooks/dossiers/useSearchDossierSummaries';

interface ProfileEnumData {
  rootPath: string;
  homePath: string;
  profilPath?: string;
  faqPath?: string;
  tutorielPath?: string;
  mentionsLegalesPath: string;
  name: string;
}

type ProfileEnumMapping = {
  [key in UserDtoProfileEnum]: ProfileEnumData;
};

const AF_ROOT_PATH = '/autorite-fourriere';
const GF_ROOT_PATH = '/gardien-fourriere';
const FO_ROOT_PATH = '/forces-de-l-ordre';
const DSR_ROOT_PATH = '/delegue-securite-routiere';
const CSU_ROOT_PATH = '/chaine-soutien-utilisateur';
const ADMIN_ROOT_PATH = '/admin';

export const FAQ_PATH = 'faq';
export const TUTORIEL_PATH = 'tutoriel';
export const PROFILE_PATH = 'profil';
export const MENTIONS_LEGALES_PATH = 'mentions-legales';
export const NOT_FOUND_PATH = '/404';

export const profileEnumMapping: ProfileEnumMapping = {
  AUTORITE_FOURRIERE: {
    name: 'Autorité de fourrière',
    homePath: AF_ROOT_PATH,
    rootPath: `${AF_ROOT_PATH}/*`,
    profilPath: `${AF_ROOT_PATH}/${PROFILE_PATH}`,
    faqPath: `${AF_ROOT_PATH}/${FAQ_PATH}`,
    tutorielPath: `${AF_ROOT_PATH}/${TUTORIEL_PATH}`,
    mentionsLegalesPath: `${AF_ROOT_PATH}/${MENTIONS_LEGALES_PATH}`,
  },
  GARDIEN_FOURRIERE: {
    name: 'Gardien de fourrière',
    homePath: GF_ROOT_PATH,
    rootPath: `${GF_ROOT_PATH}/*`,
    profilPath: `${GF_ROOT_PATH}/${PROFILE_PATH}`,
    faqPath: `${GF_ROOT_PATH}/${FAQ_PATH}`,
    tutorielPath: `${GF_ROOT_PATH}/${TUTORIEL_PATH}`,
    mentionsLegalesPath: `${GF_ROOT_PATH}/${MENTIONS_LEGALES_PATH}`,
  },
  FORCE_DE_L_ORDRE: {
    name: "Forces de l'ordre",
    homePath: FO_ROOT_PATH,
    rootPath: `${FO_ROOT_PATH}/*`,
    profilPath: `${FO_ROOT_PATH}/${PROFILE_PATH}`,
    faqPath: `${FO_ROOT_PATH}/${FAQ_PATH}`,
    tutorielPath: `${FO_ROOT_PATH}/${TUTORIEL_PATH}`,
    mentionsLegalesPath: `${FO_ROOT_PATH}/${MENTIONS_LEGALES_PATH}`,
  },
  DSR_BLR: {
    name: 'DSR',
    homePath: DSR_ROOT_PATH,
    rootPath: `${DSR_ROOT_PATH}/*`,
    mentionsLegalesPath: `${DSR_ROOT_PATH}/${MENTIONS_LEGALES_PATH}`,
  },
  CSU: {
    name: 'CSU',
    rootPath: CSU_ROOT_PATH,
    homePath: `${CSU_ROOT_PATH}/*`,
    mentionsLegalesPath: `${CSU_ROOT_PATH}/${MENTIONS_LEGALES_PATH}`,
  },
  ADMIN_FONCTIONNEL: {
    name: 'Administrateur fonctionnel',
    homePath: ADMIN_ROOT_PATH,
    rootPath: `${ADMIN_ROOT_PATH}/*`,
    mentionsLegalesPath: `${ADMIN_ROOT_PATH}/${MENTIONS_LEGALES_PATH}`,
  },
};

interface ClassementEnumData {
  label: string;
}

type ClassementEnumMapping = {
  [key in
    | TraitementResponseDtoClassementEnum
    | TraitementSummaryDtoClassementEnum]: ClassementEnumData;
};

export const classementEnumMapping: ClassementEnumMapping = {
  ALIENATION: { label: 'Pour aliénation' },
  DESTRUCTION: { label: 'Pour destruction' },
  MANUEL: { label: 'Données du classement à compléter' },
};

interface StatutFacturationEnumData {
  label: string;
}

type StatutFacturationEnumMapping = {
  [key in StatutTraitementFacturation]: StatutFacturationEnumData;
};

export const statutFacturationEnumMapping: StatutFacturationEnumMapping = {
  TRAITE: { label: 'Traité' },
  NON_TRAITE: { label: 'Non traité' },
};

interface StatutEnumData {
  label: string;
  tagColor: string;
}

type StatutEnumMapping = {
  [key in TraitementResponseDtoListStatutEnum]: StatutEnumData;
};

export const statutEnumMapping: StatutEnumMapping = {
  A_REMPLIR: {
    label: 'À remplir',
    tagColor: '#ffb2b2',
  },
  ENREGISTRE: {
    label: 'Enregistré',
    tagColor: '#7cd0ff',
  },
  NOTIFIE: {
    label: 'Notifié',
    tagColor: '#ffdb59',
  },
  A_NOTIFIER: {
    label: 'À notifier',
    tagColor: '#FF7A00',
  },
  EN_COURS_DE_NOTIFICATION: {
    label: 'En cours de notification',
    tagColor: '#FF7A87',
  },
  NOTIFICATION_ENVOYEE: {
    label: 'Notification envoyée',
    tagColor: '#E3C34F',
  },
  REMIS_AU_DOMAINE: {
    label: 'Remis aux domaines',
    tagColor: '#f6d0ff',
  },
  MIS_EN_VENTE: {
    label: 'Mis en vente',
    tagColor: '#a55fff',
  },
  VENDU: {
    label: 'Vendu',
    tagColor: '#dd7bff',
  },
  A_RESTITUER: {
    label: 'À restituer',
    tagColor: '#266b2d',
  },
  A_DETRUIRE: {
    label: 'À détruire',
    tagColor: '#10cd00',
  },
  SORTI: {
    label: 'Sorti',
    tagColor: '#0083cc',
  },
  DETRUIT: {
    label: 'Détruit',
    tagColor: '#afb757',
  },
  NON_GERE: {
    label: 'Non géré',
    tagColor: '#000000',
  },
  A_REMETTRE_AUX_DOMAINES: {
    label: 'À remettre aux domaines',
    tagColor: '#000000',
  },
};

export type AvailableFilesMapping = {
  [key in ComputedResponseDtoListAvailableFilesEnum]: string;
};

export const availableFilesMapping: AvailableFilesMapping = {
  BON_ENLEVEMENT_DESTRUCTION: "Bon d'enlèvement pour destruction",
  CONSTAT_ABANDON: "Constat d'abandon",
  DECISION_CLASSEMENT: 'Décision de classement',
  DECISION_DESTRUCTION: 'Décision de destruction',
  DECISION_REMISE_DOMAINE: 'Décision de remise au domaine',
  FICHE_DESCRIPTIVE: 'Fiche descriptive',
  PRESCRIPTION_MEF: 'Prescription de mise en fourrière',
  MAIN_LEVEE: 'Mainlevée',
};

export type CsvReferentielNames = {
  [key in ReferentielEnumDtoReferentialEnumCSVEnum]: string;
};

export const csvReferentielNames: CsvReferentielNames = {
  AUTORITE_FOURRIERE: 'Autorité fourrière',
  BRANCHE: 'Branche',
  CENTRE_VHU: 'Centre VHU',
  ETAT: 'Etat',
  FAQ: 'FAQ',
  FOURRIERE: 'Fourriere',
  GENRE_SIMPLIFIE: 'Genre simplifié',
  FOOTER_LINK: 'Lien du pied de page',
  MOTIF_MEF: 'Motif MEF',
  MOTIF_SORTIE: 'Motif sortie',
  TYPE_AF: 'Type AF',
  UNITE_FO: 'Unité FO',
  VIDEO_TUTORIEL: 'Vidéo tutoriel',
};

export type ImportCsvReferentielNames = {
  [key in ReferentielEnumDtoImportReferentielEnumCSVEnum]: string;
};

export const importCsvReferentielNames: ImportCsvReferentielNames = {
  BRANCHE: 'Branche',
  ETAT: 'Etat',
  GENRE_SIMPLIFIE: 'Genre simplifié',
  FOOTER_LINK: 'Lien du pied de page',
  VIDEO_TUTORIEL: 'Vidéo tutoriel',
};

export type JsonReferentielNames = {
  [key in ReferentielEnumDtoReferentialEnumJSONEnum]: string;
};

export const jsonReferentielNames: JsonReferentielNames = {
  CATEGORIE: 'Catégorie',
};

export type UserMapping = {
  [key in UserDtoProfileEnum]: string;
};

export const userMapping: UserMapping = {
  CSU: 'CSU',
  ADMIN_FONCTIONNEL: 'Admin fonctionnel',
  DSR_BLR: 'DSR',
  AUTORITE_FOURRIERE: 'Autorité de Fourrière',
  GARDIEN_FOURRIERE: 'Gardien de Fourrière',
  FORCE_DE_L_ORDRE: "Force de l'ordre",
};

type EtatMapping = {
  [key in EtatDtoEtatEnum]: {
    label: string;
    requestValue: FicheVehiculeRequestDtoEtatLibelleEnum;
  };
};

export const etatMapping: EtatMapping = {
  BON_ETAT: {
    label: 'Bon état',
    requestValue: FicheVehiculeRequestDtoEtatLibelleEnum.BON_ETAT,
  },
  DEGRADE: {
    label: 'Dégradé',
    requestValue: FicheVehiculeRequestDtoEtatLibelleEnum.DEGRADE,
  },
  DOMMAGES_GRAVES: {
    label: 'Dommages graves',
    requestValue: FicheVehiculeRequestDtoEtatLibelleEnum.DOMMAGES_GRAVES,
  },
};

type QualiteAutoriteMapping = {
  [key in AutoritePrescriptriceDtoQualiteAutoriteEnum]: {
    label: string;
    requestValue: FicheInfractionRequestDtoQualiteAutoriteEnum;
  };
};

export const qualiteAutoriteMapping: QualiteAutoriteMapping = {
  APJ: {
    label: 'APJ',
    requestValue: FicheInfractionRequestDtoQualiteAutoriteEnum.APJ,
  },
  APJA: {
    label: 'APJA',
    requestValue: FicheInfractionRequestDtoQualiteAutoriteEnum.APJA,
  },
  OPJ: {
    label: 'OPJ',
    requestValue: FicheInfractionRequestDtoQualiteAutoriteEnum.OPJ,
  },
};

export type GradeAgentEnumMapping = {
  [key in MainLeveeDtoGradeAgentEnum]: {
    requestKey: UpdateMainLeveeRequestDtoGradeAgentEnum;
    label: string;
  };
};

export const gradesAgent: GradeAgentEnumMapping = {
  APJ: {
    requestKey: UpdateMainLeveeRequestDtoGradeAgentEnum.APJ,
    label: 'APJ',
  },
  APJA: {
    requestKey: UpdateMainLeveeRequestDtoGradeAgentEnum.APJA,
    label: 'APJA',
  },
  OPJ: {
    requestKey: UpdateMainLeveeRequestDtoGradeAgentEnum.OPJ,
    label: 'OPJ',
  },
};

export type TypeRestitutionEnumMapping = {
  [key in MainLeveeDtoTypeRestitutionEnum]: {
    requestKey: UpdateMainLeveeRequestDtoTypeRestitutionEnum;
    label: string;
  };
};

export const typesRestitution: TypeRestitutionEnumMapping = {
  ASSURANCE_PERMIS: {
    requestKey: UpdateMainLeveeRequestDtoTypeRestitutionEnum.ASSURANCE_PERMIS,
    label:
      "Présentation de l’attestation d'assurance couvrant le véhicule et de son permis de conduire en cours de validité correspondant à la catégorie du véhicule concerné",
  },
  ASSURANCE_REMORQUE: {
    requestKey: UpdateMainLeveeRequestDtoTypeRestitutionEnum.ASSURANCE_REMORQUE,
    label:
      "Décision de faire appel au professionnel qualifié désigné pour la prise en remorque du véhicule vers un lieu de son choix et présentation de l’attestation d'assurance couvrant le véhicule en fourrière",
  },
};

export const typeMarqueMapping: {
  [key in MarqueDtoTypeEnum]: string;
} = {
  INCONNUE: 'Inconnue',
  APPROXIMATIVE: 'Approximative',
  EXACTE: 'Exacte',
};

export const categorieMarqueMapping: {
  [key in MarqueDtoCategorieEnum]: string;
} = {
  A_CATEGORISER: 'À Catégoriser',
  NON_VALORISABLE: 'Non Valorisable',
  PREMIUM: 'Premium',
};
