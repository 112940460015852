import React, { useEffect } from 'react';
import { List as AntdList, Modal as AntdModal } from 'antd';

import DownloadableFileProvider from 'hooks/DownloadableFilesContext';
import useDownloadableFiles from './useDownloadableFiles';
import DownloadableFileItem from './DownloadableFileItem/DownloadableFileItem';
import { isAnyFileStillNotGenerated } from './utils';
import { DOWNLOADABLE_FILES_POLLING_DELAY } from './constants';

import './DownloadableFiles.less';

interface ExportFilesProps {
  children: React.ReactNode;
}

const ExportFiles = ({ children }: ExportFilesProps): React.ReactElement => {
  const {
    downloadPopUpVisible,
    files,
    newAvailableFilesNumber,
    showDownloadablePopUp,
    getDownloadableFiles,
  } = useDownloadableFiles();

  useEffect(() => {
    // The polling is activated only if the component is visible and
    // there are still some files which have not yet been generated
    if (isAnyFileStillNotGenerated(files)) {
      const interval = setInterval(
        getDownloadableFiles,
        DOWNLOADABLE_FILES_POLLING_DELAY,
      );
      return () => clearInterval(interval);
    }
  }, [files, downloadPopUpVisible, getDownloadableFiles]);

  return (
    <DownloadableFileProvider
      value={{
        files,
        newDownloadsNumber: newAvailableFilesNumber,
        getDownloadableFiles,
        showDownloadablePopUp,
      }}
    >
      {children}
      <AntdModal
        visible={downloadPopUpVisible}
        onCancel={() => showDownloadablePopUp(false)}
        maskClosable={false}
        footer={null}
      >
        <h2 className="FileListTitle">Liste des fichiers téléchargeables</h2>
        <div className="FileListArea">
          {files.length === 0 ? (
            <div className="NoAvailableFile">
              {"Aucun fichier n'est disponible au téléchargement."}
            </div>
          ) : (
            <AntdList
              dataSource={files}
              renderItem={item => <DownloadableFileItem file={item} />}
            />
          )}
        </div>
      </AntdModal>
    </DownloadableFileProvider>
  );
};

export default ExportFiles;
