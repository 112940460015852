import {
  AutoriteFourriereAutocompleteDepartementResultDto,
  AutoriteFourriereAutocompleteDepartementResultDtoFieldEnum,
} from 'lib_api/lib/api/gen';

import { useApi } from 'hooks/ApiStoreContext';
import searchDepartementAutoriteFourriere from 'search/searchDepartementAutoriteFourriere';

export interface FilterAutocompleteDepartementAf {
  field: AutoriteFourriereAutocompleteDepartementResultDtoFieldEnum;
  search?: string;
}

function useSearchDepartementAf(): (
  filters: FilterAutocompleteDepartementAf,
) => Promise<AutoriteFourriereAutocompleteDepartementResultDto> {
  const controller = useApi().ReferentielControllerApi;

  return filters => {
    return searchDepartementAutoriteFourriere(
      controller,
      filters.field,
      filters.search ?? '',
    );
  };
}

export default useSearchDepartementAf;
