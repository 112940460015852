import React from 'react';
import { Alert as AntdAlert, Modal as AntdModal } from 'antd';
import {
  ComputedResponseDtoListActionsEnum,
  ComputedResponseDtoListAvailableFilesEnum,
} from 'lib_api/lib/api/gen';
import { useLocation } from 'react-router-dom';

import { ActionsRequisesLinkState } from 'utils/actionsRequisesUtils';
import { useBoolean } from 'utils/genericUtils';
import { extractNonnullDossierIdOrThrow } from 'utils/dossierUtils';
import { useDossierContext } from 'hooks/dossiers/DossierContext';
import { useApi } from 'hooks/ApiStoreContext';
import { useFOFilterContext } from 'hooks/FOFilterStoreContext';
import { useRefUniteFo } from 'hooks/referentiels/useRefUniteFo';
import { useDisplayActionsRequisesBanner } from 'hooks/useActionsRequises';
import { useUserStore } from 'hooks/UserStoreContext';
import ActionAndFileWrapper from 'components/ActionAndFileWrapper/ActionAndFileWrapper';
import { AvailableAction } from 'components/ActionAndFileWrapper/types';
import MainLeveeForm from 'components/FicheDescriptiveDisplay/MainLevee/MainLeveeForm';
import CoordonneesProprietaireForm from 'components/FicheDescriptiveDisplay/CoordonneesProprietaire/CoordonneesProprietaireForm';
import ModalProlongation from 'components/FicheDescriptiveDisplay/Prolongation/ModalProlongation';
import ModalControlerDonneesVehicule from 'components/FicheDescriptiveDisplay/ControlerDonneesVehicule/ModalControlerDonneesVehicule';
import { ActionSaisieInfoNotification } from './InfosNotification/ActionSaisieInfoNotification';
import EditionNotificationForm from './EditionNotification/EditionNotificationForm';

import './ActionsSuiviVehicule.less';

const ActionsSuiviVehicule = (): React.ReactElement => {
  const user = useUserStore();
  const { state } = useLocation() as { state: ActionsRequisesLinkState };

  const {
    value: isMainLeveeOpen,
    setIsTrue: startIsMainLeveeOpen,
    setIsFalse: stopIsMainLeveeOpen,
  } = useBoolean();

  const {
    value: isProlongationOpen,
    setIsTrue: startIsProlongationOpen,
    setIsFalse: stopIsProlongationOpen,
  } = useBoolean(state?.openProlongationMef);

  const {
    value: isSaisieInfoNotificationOpen,
    setIsTrue: startIsSaisieInfoNotificationOpen,
    setIsFalse: stopIsSaisieInfoNotificationOpen,
  } = useBoolean(state?.openSuiviNotification);

  const {
    value: isEditionNotificationOpen,
    setIsTrue: startIsEditionNotificationOpen,
    setIsFalse: stopIsEditionNotificationOpen,
  } = useBoolean();

  const {
    value: isUpdateInfosProprietaireOpen,
    setIsTrue: startIsUpdateInfosProprietaireOpen,
    setIsFalse: stopIsUpdateInfosProprietaireOpen,
  } = useBoolean(state?.openCoordonneesProprietaire);

  const {
    value: isControlerDonnesVehiculeOpen,
    setIsTrue: startIsControlerDonnesVehiculeOpen,
    setIsFalse: stopIsControlerDonnesVehiculeOpen,
  } = useBoolean(state?.openControlerDonnees);

  const foController = useApi().ForceOrdreControllerApi;
  const [dossier] = useDossierContext();
  const [, , foActionsRequises] = useFOFilterContext();
  const dossierId = extractNonnullDossierIdOrThrow(dossier);

  // display the actions requises banner(s)
  useDisplayActionsRequisesBanner(dossier, foActionsRequises);

  const filesList: ComputedResponseDtoListAvailableFilesEnum[] =
    dossier.computed?.listAvailableFiles ?? [];

  const uniteParent = dossier.computed?.uniteFODto?.idCorrelationParent
    ? useRefUniteFo(dossier.computed.uniteFODto.idCorrelationParent)
    : undefined;

  function mapComputedListActionsEnumToAvailableAction(
    actionEnum: ComputedResponseDtoListActionsEnum,
  ): AvailableAction[] {
    switch (actionEnum) {
      case ComputedResponseDtoListActionsEnum.SAISIE_NOTIFICATION_MANUELLE:
        return [
          {
            title: "Suivre l'envoi de la notification",
            onClick: startIsSaisieInfoNotificationOpen,
          },
          {
            title: 'Editer la notification',
            onClick: startIsEditionNotificationOpen,
          },
        ];

      case ComputedResponseDtoListActionsEnum.MODIFIER_COORDONNEES_PROPRIETAIRE:
        return [
          {
            title: 'Modifier les coordonnées du propriétaire',
            onClick: startIsUpdateInfosProprietaireOpen,
          },
        ];

      case ComputedResponseDtoListActionsEnum.MAIN_LEVEE:
        return [
          {
            title: filesList.includes(
              ComputedResponseDtoListAvailableFilesEnum.MAIN_LEVEE,
            )
              ? 'Modifier la mainlevée'
              : 'Editer la mainlevée',
            onClick: startIsMainLeveeOpen,
          },
        ];

      case ComputedResponseDtoListActionsEnum.PROLONGER_MEF:
        return [
          {
            title: 'Prolonger la mise en fourrière administrative',
            onClick: startIsProlongationOpen,
          },
        ];

      case ComputedResponseDtoListActionsEnum.CONTROLER_DONNEES_VEHICULE:
        return [
          {
            title: 'Contrôler les données du véhicule',
            onClick: startIsControlerDonnesVehiculeOpen,
          },
        ];
      default:
        return [];
    }
  }

  const actions: AvailableAction[] =
    dossier.computed?.listActions?.flatMap(
      mapComputedListActionsEnumToAvailableAction,
    ) ?? [];

  const responsibleIdCorrelationUniteFo =
    dossier.computed?.uniteFODto?.idCorrelationParent ||
    dossier.computed?.uniteFODto?.idCorrelation;
  const responsibleAbreviationUniteFO =
    uniteParent?.abreviation || dossier.computed?.uniteFODto?.abreviation;

  return (
    <>
      {user.idCorrelationUniteFO === responsibleIdCorrelationUniteFo ? (
        <ActionAndFileWrapper
          filesList={filesList}
          actions={actions}
          dossierId={dossierId}
        />
      ) : (
        <AntdAlert
          className={'noActionsMessage'}
          message={`Aucune action ne peut être effectuée sur ce dossier car il est rattaché à l’unité ${responsibleAbreviationUniteFO}`}
          type={'info'}
          showIcon={true}
        />
      )}

      {/* Action édition main levée */}
      <AntdModal
        visible={isMainLeveeOpen}
        destroyOnClose={true}
        footer={null}
        onCancel={stopIsMainLeveeOpen}
        maskClosable={false}
        width={680}
      >
        <MainLeveeForm close={stopIsMainLeveeOpen} />
      </AntdModal>

      {/* Action modification coordonnées propriétaire */}
      <AntdModal
        visible={isUpdateInfosProprietaireOpen}
        destroyOnClose={true}
        footer={null}
        onCancel={stopIsUpdateInfosProprietaireOpen}
        maskClosable={false}
        width={680}
      >
        <CoordonneesProprietaireForm
          controller={foController}
          close={stopIsUpdateInfosProprietaireOpen}
          editable={true}
        />
      </AntdModal>

      {/* Action modification coordonnées notification */}
      <AntdModal
        visible={isEditionNotificationOpen}
        destroyOnClose={true}
        footer={null}
        onCancel={stopIsEditionNotificationOpen}
        maskClosable={false}
        width={680}
      >
        <EditionNotificationForm close={stopIsEditionNotificationOpen} />
      </AntdModal>

      {/* prolonger mise en fourriere modal */}
      <ModalProlongation
        visible={isProlongationOpen}
        close={stopIsProlongationOpen}
      />

      {/* contrôler les données du véhicule modal */}
      <AntdModal
        visible={isControlerDonnesVehiculeOpen}
        onCancel={stopIsControlerDonnesVehiculeOpen}
        title="Contrôler les données du véhicule"
        closable={true}
        maskClosable={true}
        className="modalControleDonneesVehicule"
        footer={null}
        width={812}
      >
        <ModalControlerDonneesVehicule
          close={stopIsControlerDonnesVehiculeOpen}
        />
      </AntdModal>

      <ActionSaisieInfoNotification
        visible={isSaisieInfoNotificationOpen}
        close={stopIsSaisieInfoNotificationOpen}
      />
    </>
  );
};

export default ActionsSuiviVehicule;
