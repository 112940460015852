import React from 'react';

import { ComputedResponseDtoListAvailableFilesEnum } from 'lib_api/lib/api/gen';
import Actions from 'components/ActionAndFileWrapper/Actions/Actions';
import AvailableFiles from 'components/ActionAndFileWrapper/AvailableFiles/AvailableFiles';

import { AvailableAction } from './types';
import './ActionAndFileWrapper.less';

interface ActionAndFileWrapperType {
  filesList: ComputedResponseDtoListAvailableFilesEnum[];
  actions: AvailableAction[];
  dossierId: string;
}

const ActionAndFileWrapper = ({
  filesList,
  actions,
  dossierId,
}: ActionAndFileWrapperType): React.ReactElement => {
  return (
    <div className={'ActionsContainer'}>
      {!!filesList.length && (
        <AvailableFiles files={filesList} dossierId={dossierId} />
      )}
      {!!actions.length && <Actions actions={actions} />}
    </div>
  );
};

export default ActionAndFileWrapper;
