import React from 'react';
import { Button as AntdButton } from 'antd';

import { DashboardAdminFonctionnelFilterFormValues } from 'pages/admin-fonctionnel/Dashboard/types';
import { useBaseFormContext } from 'components/BaseForm/BaseFormContext';

import './FormFooter.less';

interface FormActionProps {
  setFilter: (
    value: Partial<DashboardAdminFonctionnelFilterFormValues>,
  ) => void;
}

export function FormAction({ setFilter }: FormActionProps): React.ReactElement {
  const context =
    useBaseFormContext<DashboardAdminFonctionnelFilterFormValues>();

  function resetFilter() {
    context.setFieldsValue({
      classement: undefined,
      dateRangeBonEnlevement: undefined,
      dateRangeMiseEnFourriere: undefined,
      dateRangeSortieDefinitive: undefined,
      fourriere: undefined,
      natureMiseEnFourriere: undefined,
      statut: undefined,
      dateRangeOrdreDestruction: undefined,
      statutsTraitementFacturation: undefined,
      motifDeSortie: undefined,
      includeDossiersAnonymises: undefined,
      idUniteFOList: undefined,
    });
  }

  return (
    <div className={'form-footer-container'}>
      <AntdButton onClick={resetFilter}>Réinitialiser</AntdButton>

      <AntdButton
        type="primary"
        onClick={() => setFilter(context.getFieldsValue())}
      >
        Rechercher
      </AntdButton>
    </div>
  );
}

export default FormAction;
