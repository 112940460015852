import React from 'react';
import { InformationBanner } from 'components/Banner';
import { ComputedResponseDtoListActionsRequisesEnum } from 'lib_api/lib/api/gen';
import { useDossierActionsRequisesContext } from 'hooks/DossierActionsRequisesContext';

function mapActionRequiseToDescription(
  actionRequise: ComputedResponseDtoListActionsRequisesEnum,
): string | null {
  switch (actionRequise) {
    case ComputedResponseDtoListActionsRequisesEnum.CLASSER_MANUELLEMENT:
      return (
        `Le classement n'a pas pu être effectué automatiquement. ` +
        `Compléter les informations du véhicule en cliquant sur ` +
        `"Modifier les données techniques du véhicule".`
      );
    case ComputedResponseDtoListActionsRequisesEnum.SAISIE_NOTIFICATION_MANUELLE:
      return (
        'Editer la notification pour ce véhicule puis renseigner ' +
        "la date d'envoi du courrier."
      );
    case ComputedResponseDtoListActionsRequisesEnum.SAISIE_VHU:
      return (
        'Le véhicule est à détruire. ' +
        "Renseigner le centre VHU puis télécharger le bon d'enlèvement."
      );
    case ComputedResponseDtoListActionsRequisesEnum.MODIFIER_COORDONNEES_PROPRIETAIRE:
      return (
        'Les coordonnées du propriétaire ne sont pas suffisantes pour le notifier. ' +
        'Compléter ses informations en cliquant sur "Modifier les coordonnées du propriétaire".'
      );
    case ComputedResponseDtoListActionsRequisesEnum.RENSEIGNER_STATUT_RETOUR_NOTIFICATION_MANUELLE:
      return (
        'La notification a été envoyée il y a 20 jours. ' +
        'Renseigner la date et le statut de retour de notification.'
      );
    case ComputedResponseDtoListActionsRequisesEnum.PROLONGER_MEF:
      return 'Renseigner la prolongation de mise en fourrière administrative. Cliquer sur "Prolonger la mise en fourrière administrative".';
    case ComputedResponseDtoListActionsRequisesEnum.ENREGISTRER_ENTREE:
      return 'Le dossier doit être complété pour finaliser l\'enregistrement du véhicule. Cliquer sur "Enregistrer l\'entrée"';
    case ComputedResponseDtoListActionsRequisesEnum.CONTROLER_DONNEES_VEHICULE:
      return 'Un doute a été émis sur la fiabilité des données du véhicule. Cliquer sur "Contrôler les données" pour les vérifier et/ou corriger.';
    default:
      return null;
  }
}

export const DossierActionRequiseBanner = (): React.ReactElement => {
  const { dossierActionsRequises } = useDossierActionsRequisesContext();

  if (dossierActionsRequises.length === 0) {
    return <></>;
  }
  return (
    <>
      {dossierActionsRequises.map(actionRequise => {
        const description = mapActionRequiseToDescription(actionRequise);
        if (description !== null) {
          return (
            <InformationBanner
              description={description}
              alertType={'warning'}
              key={actionRequise.toString()}
              closable={false}
            />
          );
        }
        return null;
      })}
    </>
  );
};
