import { ErrorDtoCodeEnum } from 'lib_api/lib/api/gen';
import { BackErrorMessages } from '../types/BackErrorMessages';

export const defaultErrorMessage =
  'Une erreur est survenue. Veuillez ré-essayer ultérieurement.';

//All elem of the Enum must be present or an error is raised by the linter
export const backErrorMessages: BackErrorMessages = {
  [ErrorDtoCodeEnum.HTTP_PARSE_ERROR]: 'La requête HTTP est malformée.',
  [ErrorDtoCodeEnum.UNKNOWN_ERROR]:
    'Une erreur est survenue. Veuillez vérifier votre connexion internet et ré-essayer ultérieurement.',
  [ErrorDtoCodeEnum.API_AUTHENTICATION_ERROR]:
    "Erreur d'authentification, veuillez vérifier vos informations.",
  [ErrorDtoCodeEnum.API_AUTHORIZATION_ERROR]:
    "Vous n'êtes pas autorisé à faire cette action.",
  [ErrorDtoCodeEnum.API_EXPIRED_TOKEN]:
    "Votre jeton d'authentification a expiré.",
  [ErrorDtoCodeEnum.API_WRONG_HEADER_IDENTIFIER]:
    "Votre jeton d'authentification a expiré.",
  [ErrorDtoCodeEnum.API_DOCUMENT_NOT_FOUND_ERROR]: 'Document introuvable.',
  [ErrorDtoCodeEnum.API_UNPROCESSABLE_FILE]: 'Ce document ne peut être traité.',
  [ErrorDtoCodeEnum.API_WRITE_HTTP_RESPONSE]: defaultErrorMessage,
  [ErrorDtoCodeEnum.API_WRONG_HEADER_IDENTIFIER]: defaultErrorMessage,
  [ErrorDtoCodeEnum.CAMUNDA_PROCESS_INSTANCE_ALREADY_EXISTS_ERROR]:
    defaultErrorMessage,
  [ErrorDtoCodeEnum.CAMUNDA_INCIDENT_NOT_FOUND]: defaultErrorMessage,
  [ErrorDtoCodeEnum.CAMUNDA_PROCESS_INSTANCE_NOT_FOUND]: defaultErrorMessage,
  [ErrorDtoCodeEnum.CAMUNDA_MESSAGE_EMISSION_ERROR]: defaultErrorMessage,
  [ErrorDtoCodeEnum.CAMUNDA_UNKNOWN_ERROR]: defaultErrorMessage,
  [ErrorDtoCodeEnum.CLASSEMENT_BAD_REQUEST]: defaultErrorMessage,
  [ErrorDtoCodeEnum.CLASSEMENT_BAD_TABLE_FORMAT_ERROR]:
    "Impossible d'importer les données.",
  [ErrorDtoCodeEnum.CLASSEMENT_EXPORT_TABLE_ERROR]:
    "Impossible d'exporter la tablel de classement",
  [ErrorDtoCodeEnum.CLASSEMENT_CSV_ERROR]: "Impossible d'importer les données.",
  [ErrorDtoCodeEnum.CLASSEMENT_FORBIDDEN_REQUEST]:
    "Impossible d'autoriser cette requête de classement",
  [ErrorDtoCodeEnum.DOSSIER_UPDATE_MAIN_LEVEE_ERROR]: defaultErrorMessage,
  [ErrorDtoCodeEnum.CONNECTION_FAILED_ERROR]: defaultErrorMessage,
  [ErrorDtoCodeEnum.DOSSIER_CLASSEMENT_UNKNOWN_ERROR]:
    'Une erreur est survenue. Veuillez vérifier votre connexion internet et ré-essayer ultérieurement.',
  [ErrorDtoCodeEnum.DOSSIER_EXISTING_BON_ENLEVEMENT_GARDIEN_ERROR]:
    "Le bon d'enlèvement spécifié pour le véhicule n'est pas correct.",
  [ErrorDtoCodeEnum.DOSSIER_INVALID_BON_ENLEVEMENT_GARDIEN_ERROR]:
    "Le centre VHU saisi n'est pas correct.",
  [ErrorDtoCodeEnum.DOSSIER_BON_ENLEVEMENT_GARDIEN_UNKNOWN_ERROR]:
    'Une erreur est survenue. Veuillez vérifier votre connexion internet et ré-essayez ultérieurement.',
  [ErrorDtoCodeEnum.DOSSIER_CREATE_ERROR]: 'Impossible de créer le dossier.',
  [ErrorDtoCodeEnum.DOSSIER_EXISTING_CLASSEMENT_ERROR]:
    "Le classement actuel du véhicule n'est pas correct",
  [ErrorDtoCodeEnum.DOSSIER_MISSING_INFO_IN_BODY]:
    'Veuillez contacter votre administrateur, il y a des champs manquants dans la requête.',
  [ErrorDtoCodeEnum.DOSSIER_MISSING_INFO_IN_HEADER]:
    'Veuillez contacter votre administrateur, il y a des champs manquants dans le header de la requête.',
  [ErrorDtoCodeEnum.DOSSIER_PROLONGATION_MEF_ERROR]:
    'Veuillez contacter votre administrateur, impossible de prolonger la mise en fourrière administrative.',
  [ErrorDtoCodeEnum.DOSSIER_UPDATE_HERMES_STATUS_ERROR]:
    'La mise à jour des informations Hermes a échoué pour certains dossiers.',
  [ErrorDtoCodeEnum.DOSSIER_NOT_FOUND]: 'Le dossier est introuvable.',
  [ErrorDtoCodeEnum.DOSSIER_WORKFLOW_SYNC_ERROR]: defaultErrorMessage,
  [ErrorDtoCodeEnum.DOSSIER_NOTIFICATION_INFOS_ERROR]: defaultErrorMessage,
  [ErrorDtoCodeEnum.NOTIFICATION_AUTO_DEMANDE_EDITION_EVENT_ERROR]:
    defaultErrorMessage,
  [ErrorDtoCodeEnum.NOTIFICATION_AUTO_POSTED_EVENT_ERROR]: defaultErrorMessage,
  [ErrorDtoCodeEnum.NOTIFICATION_AUTO_INFOS_RETOUR_EVENT_ERROR]:
    defaultErrorMessage,
  [ErrorDtoCodeEnum.DOSSIER_EVENT_NOT_FOUND]: defaultErrorMessage,
  [ErrorDtoCodeEnum.DOSSIER_UNKNOWN_ERROR]:
    'Une erreur est survenue. Veuillez vérifier votre connexion internet et ré-essayer ultérieurement.',
  [ErrorDtoCodeEnum.DOSSIER_UPDATE_ERROR]:
    'Impossible de mettre à jour le dossier.',
  [ErrorDtoCodeEnum.DOSSIER_SORTIE_VEHICULE_ERROR]:
    "Impossible d'autoriser la sortie du véhicule.",
  [ErrorDtoCodeEnum.DOSSIER_WORKFLOW_RESET_ERROR]:
    'Impossible de réinitialiser le workflow. Veuillez réessayer ultérieurement',
  [ErrorDtoCodeEnum.DOSSIER_ABANDON_ERROR]: defaultErrorMessage,
  [ErrorDtoCodeEnum.REFERENTIEL_NOT_FOUND]:
    'Une valeur est introuvable dans un référentiel.',
  [ErrorDtoCodeEnum.REFERENTIEL_NO_ACTIVE_FOUND]:
    'Une valeur active est introuvable dans un référentiel.',
  [ErrorDtoCodeEnum.REFERENTIEL_MULTIPLE_ACTIVE_FOUND]:
    'Plusieurs valeurs valides ont été trouvées dans un référentiel.',
  [ErrorDtoCodeEnum.REFERENTIEL_PROFILE_NOT_FOUND]:
    "Le profil utilisateur n'existe pas.",
  [ErrorDtoCodeEnum.REFERENTIEL_UNKNOWN_ERROR]:
    'Une erreur est survenue. Veuillez vérifier votre connexion internet et ré-essayer ultérieurement.',
  [ErrorDtoCodeEnum.REFERENTIEL_FORBIDDEN_IMPORT]:
    "Le référentiel ne peut être importé s'il est déjà initialisé.",
  [ErrorDtoCodeEnum.REFERENTIEL_INVALID_DATA]: 'Le fichier ne peut être lu.',
  [ErrorDtoCodeEnum.REFERENTIEL_INVALID_FILE]:
    'Le fichier ne peut être ouvert.',
  [ErrorDtoCodeEnum.REFERENTIEL_UNABLE_CAST_SIV_DATA]:
    'Impossible de traiter les données du SIV.',
  [ErrorDtoCodeEnum.REFERENTIEL_CREATE_UNITE_FO_ERROR]:
    "Impossible de créer l'unité.",
  [ErrorDtoCodeEnum.REFERENTIEL_CREATE_AUTORITE_FOURRIERE_ERROR]:
    "Impossible de créer l'autorité de fourrière.",
  [ErrorDtoCodeEnum.REFERENTIEL_UPDATE_PROFIL_UNITE_FO_ERROR]:
    "Impossible de mettre à jour le profil de l'unité.",
  [ErrorDtoCodeEnum.REFERENTIEL_UPDATE_UNITE_FO_ERROR]:
    "Impossible de mettre à jour l'unité.",
  [ErrorDtoCodeEnum.REFERENTIEL_UPDATE_AF_ERROR]:
    "Impossible de mettre à jour l'autorité de fourrière.",
  [ErrorDtoCodeEnum.REFERENTIEL_UPDATE_GF_ERROR]:
    'Impossible de mettre à jour le gardien de fourrière.',
  [ErrorDtoCodeEnum.REFERENTIEL_CREATE_INFORMATION_MESSAGE_ERROR]:
    "Impossible de créer le message d'information. ",
  [ErrorDtoCodeEnum.REFERENTIEL_UPDATE_INFORMATION_MESSAGE_ERROR]:
    "Impossible de mettre à jour le message d'information. ",
  [ErrorDtoCodeEnum.EXPORT_FILES_UNKNOWN_ERROR]:
    'Une erreur est survenue. Veuillez vérifier votre connexion internet et ré-essayer ultérieurement',
  [ErrorDtoCodeEnum.EXPORT_FILES_WRITE_CSV_ERROR]:
    "Impossible d'exporter les données.",
  [ErrorDtoCodeEnum.EXPORT_FILES_WRITE_ZIP_ERROR]:
    "Impossible d'exporter les données.",
  [ErrorDtoCodeEnum.EXPORT_FILES_BAD_REQUEST]:
    "Impossible d'exporter les données. La requête n'est pas correcte.",
  [ErrorDtoCodeEnum.EXPORT_FILE_NOT_FOUND_EXPORT_ERROR]:
    "Impossible d'accéder à l'export, l'export demandé est introuvable.",
  [ErrorDtoCodeEnum.EXPORT_FILE_NOT_GENERATED_EXPORT_ERROR]:
    "Impossible d'accéder à l'export, le fichier n'est pas encore généré.",
  [ErrorDtoCodeEnum.EXPORT_FILE_EXPIRED_EXPORT_ERROR]:
    "Impossible d'accéder à l'export, le fichier a expiré.",
  [ErrorDtoCodeEnum.EXPORT_FILE_FAILED_EXPORT_ERROR]:
    "Impossible d'accéder à l'export, le fichier a rencontré une erreur lors de sa génération.",
  [ErrorDtoCodeEnum.EXPORT_FILE_CANNOT_ACCESS_FILE_ERROR]:
    "Impossible d'accéder à l'export, le fichier n'est pas accessible.",
  [ErrorDtoCodeEnum.SPRING_BAD_REQUEST]:
    "Impossible de traiter la requête. La requête n'est pas correcte.",
  [ErrorDtoCodeEnum.WORKFLOW_CONNECTION_FAILED]: defaultErrorMessage,
  [ErrorDtoCodeEnum.WORKFLOW_PARSE_JSON_FAILED]: defaultErrorMessage,
  [ErrorDtoCodeEnum.WORKFLOW_UNKNOWN_ERROR]: defaultErrorMessage,
  [ErrorDtoCodeEnum.WORKFLOW_WORKER_ERROR]: defaultErrorMessage,
  [ErrorDtoCodeEnum.WORKFLOW_SYNC_PROCESS_FAILED]: defaultErrorMessage,
  [ErrorDtoCodeEnum.RIE_SIV_BUILD_SOAP_HEADER_FAILED]: defaultErrorMessage,
  [ErrorDtoCodeEnum.RIE_SIV_RESPONSE_PARSE_XML_TO_DTO_FAILED]:
    defaultErrorMessage,
  [ErrorDtoCodeEnum.RIE_SIV_RESPONSE_WITH_ERRORS]: defaultErrorMessage,
  [ErrorDtoCodeEnum.RIE_SIV_BAD_REQUEST]: defaultErrorMessage,
  [ErrorDtoCodeEnum.RIE_SIV_SEND_REQUEST_FAILED]: defaultErrorMessage,
  [ErrorDtoCodeEnum.RIE_SIV_RESPONSE_PARSE_TO_XML_READER_FAILED]:
    defaultErrorMessage,
  [ErrorDtoCodeEnum.RIE_SIV_UNKNOWN_ERROR]: defaultErrorMessage,
  [ErrorDtoCodeEnum.DOSSIER_UPDATE_SIV_INFORMATION_ERROR]: defaultErrorMessage,
  [ErrorDtoCodeEnum.DOSSIER_COMPARAISON_SIV_FD_ERROR]: defaultErrorMessage,
  [ErrorDtoCodeEnum.NOTIFICATION_DIRECTORY_ERROR]: defaultErrorMessage,
  [ErrorDtoCodeEnum.NOTIFICATION_UNABLE_TO_PARSE_XML]: defaultErrorMessage,
  [ErrorDtoCodeEnum.NOTIFICATION_UNKNOWN_ERROR]: defaultErrorMessage,
  [ErrorDtoCodeEnum.NOTIFICATION_DECOMPRESSION_ARCHIVE_ERROR]:
    defaultErrorMessage,
  [ErrorDtoCodeEnum.PROPERTY_ERROR]: defaultErrorMessage,
  [ErrorDtoCodeEnum.DOSSIER_RGPD_DATA_NOT_FOUND]: defaultErrorMessage,
  [ErrorDtoCodeEnum.INES_SAS_INVALID_USER]: defaultErrorMessage,
  [ErrorDtoCodeEnum.INES_SAS_INVALID_MESSAGE]: defaultErrorMessage,
  [ErrorDtoCodeEnum.INES_SAS_UNKNOWN_ERROR]: defaultErrorMessage,
  [ErrorDtoCodeEnum.COMPTES_UTILISATEURS_RESPONSE_CSV_EXCEPTION]:
    defaultErrorMessage,
  [ErrorDtoCodeEnum.COMPTES_UTILISATEURS_KEYCLOAK_CONNECT_ERROR]:
    defaultErrorMessage,
  [ErrorDtoCodeEnum.COMPTES_UTILISATEURS_UNKNOWN_ERROR]: defaultErrorMessage,
  [ErrorDtoCodeEnum.COMPTES_UTILISATEURS_BAD_CSV_LINES]:
    "L'en-tête du fichier n'est pas celui requis.",
  [ErrorDtoCodeEnum.COMPTES_UTILISATEURS_UNABLE_READ_FILE]:
    'Le contenu du fichier ne peut être reconnu.',
  [ErrorDtoCodeEnum.COMPTES_UTILISATEURS_UNABLE_PARSE_JSON_RESPONSE]:
    defaultErrorMessage,
  [ErrorDtoCodeEnum.COMPTES_UTILISATEURS_ERROR_KEYCLOAK_GROUP_CONFIGURATION]:
    defaultErrorMessage,
  [ErrorDtoCodeEnum.COMPTES_UTILISATEURS_ENTITY_NOT_FOUND]: defaultErrorMessage,
  [ErrorDtoCodeEnum.COMPTES_UTILISATEURS_UPDATE_ERROR]: defaultErrorMessage,
  [ErrorDtoCodeEnum.COMPTES_UTILISATEURS_CREATE_ERROR]: defaultErrorMessage,
  [ErrorDtoCodeEnum.COMPTES_UTILISATEURS_CLIENT_FORBIDDEN_ERROR]:
    defaultErrorMessage,
  [ErrorDtoCodeEnum.COMPTES_UTILISATEURS_CLIENT_UNKNOWN_ERROR]:
    defaultErrorMessage,
  [ErrorDtoCodeEnum.COMPTES_UTILISATEURS_CREATE_USERNAME_MAX_INCREMENTS_EXCEEDED]:
    'Impossible de créer un utilisateur avec ce nom et ce prénom, le nombre maximal a été atteint.',
  [ErrorDtoCodeEnum.COMPTES_UTILISATEURS_USER_NOT_FOUND]: defaultErrorMessage,
  [ErrorDtoCodeEnum.HERMES_BAD_CSV_LINES]:
    "L'en-tête du fichier n'est pas celui requis.",
  [ErrorDtoCodeEnum.HERMES_UNREADABLE_FILE]:
    'Le contenu du fichier ne peut être reconnu.',
  [ErrorDtoCodeEnum.IDP_RESPONSE_ERROR]: defaultErrorMessage,
  [ErrorDtoCodeEnum.IDP_INVALID_REQUEST]: defaultErrorMessage,
  [ErrorDtoCodeEnum.IDP_INVALID_CLIENT]: defaultErrorMessage,
  [ErrorDtoCodeEnum.IDP_INVALID_GRANT]: defaultErrorMessage,
  [ErrorDtoCodeEnum.IDP_UNAUTHORIZED_CLIENT]: defaultErrorMessage,
  [ErrorDtoCodeEnum.IDP_UNSUPPORTED_GRANT_TYPE]: defaultErrorMessage,
  [ErrorDtoCodeEnum.IDP_INVALID_SCOPE]: defaultErrorMessage,
  [ErrorDtoCodeEnum.KEYCLOAK_MISSING_PROPERTY]: defaultErrorMessage,
  [ErrorDtoCodeEnum.KEYCLOAK_INVALID_ROLE]: defaultErrorMessage,
  [ErrorDtoCodeEnum.KEYCLOAK_AUTORITE_FOURRIERE_INVALID]: defaultErrorMessage,
  [ErrorDtoCodeEnum.KEYCLOAK_AUTORITE_FOURRIERE_NOT_FOUND]: defaultErrorMessage,
  [ErrorDtoCodeEnum.KEYCLOAK_AUTORITE_FOURRIERE_NO_ACTIVE_FOUND]:
    defaultErrorMessage,
  [ErrorDtoCodeEnum.KEYCLOAK_AUTORITE_FOURRIERE_MULTIPLE_ACTIVE_FOUND]:
    defaultErrorMessage,
  [ErrorDtoCodeEnum.KEYCLOAK_FOURRIERE_INVALID]: defaultErrorMessage,
  [ErrorDtoCodeEnum.KEYCLOAK_FOURRIERE_NOT_FOUND]: defaultErrorMessage,
  [ErrorDtoCodeEnum.KEYCLOAK_FOURRIERE_NO_ACTIVE_FOUND]: defaultErrorMessage,
  [ErrorDtoCodeEnum.KEYCLOAK_FOURRIERE_MULTIPLE_ACTIVE_FOUND]:
    defaultErrorMessage,
  [ErrorDtoCodeEnum.KEYCLOAK_UNITE_FO_INVALID]: defaultErrorMessage,
  [ErrorDtoCodeEnum.KEYCLOAK_UNITE_FO_NOT_FOUND]: defaultErrorMessage,
  [ErrorDtoCodeEnum.KEYCLOAK_UNITE_FO_NO_ACTIVE_FOUND]: defaultErrorMessage,
  [ErrorDtoCodeEnum.KEYCLOAK_UNITE_FO_MULTIPLE_ACTIVE_FOUND]:
    defaultErrorMessage,
  [ErrorDtoCodeEnum.PASSAGE2_MISSING_PROPERTY]: defaultErrorMessage,
  [ErrorDtoCodeEnum.PASSAGE2_INVALID_ROLE]: defaultErrorMessage,
  [ErrorDtoCodeEnum.PASSAGE2_CODE_INSEE_NOT_FOUND]: defaultErrorMessage,
  [ErrorDtoCodeEnum.PASSAGE2_CODE_INSEE_NO_ACTIVE_FOUND]: defaultErrorMessage,
  [ErrorDtoCodeEnum.PASSAGE2_CODE_INSEE_MULTIPLE_ACTIVE_FOUND]:
    defaultErrorMessage,
  [ErrorDtoCodeEnum.PASSAGE2_LIBELLE_LONG_INVALID]: defaultErrorMessage,
  [ErrorDtoCodeEnum.PASSAGE2_UNITE_FO_ABREVIATION_NOT_FOUND]:
    defaultErrorMessage,
  [ErrorDtoCodeEnum.PASSAGE2_UNITE_FO_ABREVIATION_NO_ACTIVE_FOUND]:
    defaultErrorMessage,
  [ErrorDtoCodeEnum.PASSAGE2_UNITE_FO_ABREVIATION_MULTIPLE_ACTIVE_FOUND]:
    defaultErrorMessage,
  [ErrorDtoCodeEnum.PASSAGE2_UNITE_FO_CODE_NOT_FOUND]: defaultErrorMessage,
  [ErrorDtoCodeEnum.PASSAGE2_UNITE_FO_CODE_NO_ACTIVE_FOUND]:
    defaultErrorMessage,
  [ErrorDtoCodeEnum.PASSAGE2_UNITE_FO_CODE_MULTIPLE_ACTIVE_FOUND]:
    defaultErrorMessage,
  [ErrorDtoCodeEnum.PASSAGE2_UNITE_FO_NOT_FOUND]: defaultErrorMessage,
  [ErrorDtoCodeEnum.PASSAGE2_UNITE_FO_NO_ACTIVE_FOUND]: defaultErrorMessage,
  [ErrorDtoCodeEnum.PASSAGE2_UNITE_FO_MULTIPLE_ACTIVE_FOUND]:
    defaultErrorMessage,
  [ErrorDtoCodeEnum.PDF_UNKNOWN_ERROR]: defaultErrorMessage,
  [ErrorDtoCodeEnum.PDF_GENERATE_DOCUMENT_ERROR]: defaultErrorMessage,
  [ErrorDtoCodeEnum.PDF_WRITE_DOCUMENT_IN_RESPONSE_ERROR]: defaultErrorMessage,
  [ErrorDtoCodeEnum.PDF_NOTIFICATION_MAPPER_ERROR]: defaultErrorMessage,
  [ErrorDtoCodeEnum.PDF_BON_ENLEVEMENT_MAPPER_ERROR]: defaultErrorMessage,
  [ErrorDtoCodeEnum.HERMES_UNKNOWN_ERROR]: defaultErrorMessage,
  [ErrorDtoCodeEnum.HERMES_UNKNOWN_RESPONSE_ERROR]: defaultErrorMessage,
  [ErrorDtoCodeEnum.HERMES_UNHANDLED_RESPONSE_ERROR]: defaultErrorMessage,
  [ErrorDtoCodeEnum.HERMES_UNPROCESSED_RESPONSE_ERROR]: defaultErrorMessage,
  [ErrorDtoCodeEnum.HERMES_MALFORMED_HERMES_ID_CORRELATION]:
    defaultErrorMessage,
  [ErrorDtoCodeEnum.HERMES_INVALID_DOSSIER_ALREADY_SENT]: defaultErrorMessage,
  [ErrorDtoCodeEnum.HERMES_INVALID_DOSSIER_NOT_ABANDONED]: defaultErrorMessage,
  [ErrorDtoCodeEnum.HERMES_INVALID_DOSSIER_NOT_ALIENATION]: defaultErrorMessage,
  [ErrorDtoCodeEnum.DEPRECATE_REFERENTIELS_UNKNOWN_ERROR]: defaultErrorMessage,
  [ErrorDtoCodeEnum.DOSSIER_ENTREE_VEHICULE_ERROR]: defaultErrorMessage,
  [ErrorDtoCodeEnum.HERMES_CONTOURNEMENT_IMPORT_DISABLED]:
    "L'import des biens via la solution de contournement est désactivé.",
  [ErrorDtoCodeEnum.NULL_IDENTIFICATION]: defaultErrorMessage,
  [ErrorDtoCodeEnum.SIV_ERROR]: defaultErrorMessage,
  [ErrorDtoCodeEnum.CLASSEMENT_INTERNAL_ERROR]: defaultErrorMessage,
  [ErrorDtoCodeEnum.FILE_ENCODING_ERROR]:
    "Le fichier n'est pas encodé au bon format.",
  [ErrorDtoCodeEnum.EXPORT_FILE_CANNOT_ACCESS_MANUEL]:
    "L'accès au manuel est impossible.",
  [ErrorDtoCodeEnum.EXPORT_FILE_CANNOT_ACCESS_FICHE_DESCRIPTIVE]:
    "L'accès au modèle de fichier descriptive est impossible.",
  [ErrorDtoCodeEnum.DEPRECATE_REFERENTIELS_UNKNOWN_ERROR]: defaultErrorMessage,
  [ErrorDtoCodeEnum.DEPRECATE_REFERENTIELS_UNABLE_TO_DEPRECATE_UNITE_PARENT]:
    defaultErrorMessage,
  [ErrorDtoCodeEnum.DEPRECATE_REFERENTIELS_UNABLE_TO_DEPRECATE_USED_BY_DOSSIER]:
    defaultErrorMessage,
  [ErrorDtoCodeEnum.DEPRECATE_REFERENTIELS_UNABLE_TO_DEPRECATE_UNITE_PARENT_AND_USED_BY_DOSSIER]:
    defaultErrorMessage,
  [ErrorDtoCodeEnum.STORE_SI_TIERS_UNKONW_ERROR]: defaultErrorMessage,
  [ErrorDtoCodeEnum.STORE_SI_TIERS_PROCESS_LOCK]: defaultErrorMessage,
  [ErrorDtoCodeEnum.STORE_SI_TIERS_FAIL_TO_SAVE_LOCK]: defaultErrorMessage,
  [ErrorDtoCodeEnum.ANTAI_UNKNOWN_ERROR]: defaultErrorMessage,
  [ErrorDtoCodeEnum.ANTAI_DEMANDE_EDITION_BAD_REQUEST]: defaultErrorMessage,
  [ErrorDtoCodeEnum.NULL_PARAMETER_ERROR]: defaultErrorMessage,
  [ErrorDtoCodeEnum.RANGE_TOO_LARGE]: defaultErrorMessage,
  [ErrorDtoCodeEnum.BEGINNING_AFTER_END]: defaultErrorMessage,
  [ErrorDtoCodeEnum.SWAPART_FAILED_TO_REACH_LOGIN]: defaultErrorMessage,
  [ErrorDtoCodeEnum.SWAPART_FAILED_TO_REACH_CONVENTIONNEMENT]:
    defaultErrorMessage,
  [ErrorDtoCodeEnum.SWAPART_LOGIN_ERROR]: defaultErrorMessage,
  [ErrorDtoCodeEnum.SWAPART_UNKNOWN_ERROR]: defaultErrorMessage,
  [ErrorDtoCodeEnum.REFERENTIEL_CREATE_CENTRE_VHU_ERROR]: defaultErrorMessage,
  [ErrorDtoCodeEnum.REFERENTIEL_UPDATE_CENTRE_VHU_ERROR]: defaultErrorMessage,
  [ErrorDtoCodeEnum.RPSI_BATCH_IS_RUNNING_EXCEPTION]: defaultErrorMessage,
  [ErrorDtoCodeEnum.DOSSIER_UPDATE_ENTITES_RATTACHEMENT_ERROR]:
    defaultErrorMessage,
  [ErrorDtoCodeEnum.DOSSIER_UPDATE_DONNEES_VEHICULE_ERROR]: defaultErrorMessage,
  [ErrorDtoCodeEnum.DILA_API_AUTHENTICATION_ERROR]: defaultErrorMessage,
  [ErrorDtoCodeEnum.DILA_UNKNOWN_ERROR]: defaultErrorMessage,
  [ErrorDtoCodeEnum.DILA_CONNECTION_FAILED_ERROR]: defaultErrorMessage,
  [ErrorDtoCodeEnum.DILA_MISSING_FOURRIERE_DTO]: defaultErrorMessage,
  [ErrorDtoCodeEnum.DILA_DOSSIER_NOT_FOUND]: defaultErrorMessage,
  [ErrorDtoCodeEnum.DILA_MISSING_IMMATRICULATION]: defaultErrorMessage,
  [ErrorDtoCodeEnum.PHOTO_UNKNOWN_ERROR]: defaultErrorMessage,
  [ErrorDtoCodeEnum.PHOTO_ALREADY_EXISTING_COTE_ERROR]: defaultErrorMessage,
  [ErrorDtoCodeEnum.PASSAGE2_UNITE_FO_ALL_STRATEGIES_FAILED]:
    defaultErrorMessage,
  [ErrorDtoCodeEnum.DICEM_BAD_REQUEST_ERROR]: defaultErrorMessage,
  [ErrorDtoCodeEnum.DICEM_UNKNOWN_ERROR]: defaultErrorMessage,
  [ErrorDtoCodeEnum.DICEM_FAILED_TO_REACH_CONSULTATION]: defaultErrorMessage,
  [ErrorDtoCodeEnum.DOSSIER_UPDATE_DICEM_INFORMATION_ERROR]:
    defaultErrorMessage,
  [ErrorDtoCodeEnum.DILA_MISSING_NUMERO_OR_DATE]: defaultErrorMessage,
  [ErrorDtoCodeEnum.DILA_FICHE_DESCRIPTIVE_NOT_FOUND]: defaultErrorMessage,
  [ErrorDtoCodeEnum.DILA_FICHE_DESCRIPTIVE_FORBIDDEN]: defaultErrorMessage,
  [ErrorDtoCodeEnum.DILA_MISSING_NUMERO_OR_DATE]: defaultErrorMessage,
  [ErrorDtoCodeEnum.NOTIFICATION_AUTO_FIND_BY_ANTAI_ID_ERROR]:
    defaultErrorMessage,
  [ErrorDtoCodeEnum.DOSSIER_CONTROLE_DONNEES_VEHICULE_ERROR]:
    "Impossible d'appliquer le contrôle des données.",
  [ErrorDtoCodeEnum.RPSI_REQUEST_LOCKED_EXCEPTION]: defaultErrorMessage,
  default: defaultErrorMessage,
};
