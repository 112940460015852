import { useCallback, useState } from 'react';
import moment from 'moment';

import {
  DossierResponseDto,
  ForceOrdreControllerApi,
  MainLeveeViolationDtoFormFieldEnum,
  UpdateMainLeveeRequestDto,
} from 'lib_api/lib/api/gen';

import { backAlertMessage } from 'hooks/utils/backAlertMessage';
import { useHandleBackErrors } from 'hooks/utils/handleBackErrors';
import { defaultErrorMessage } from 'utils/ErrorMessages';
import { UNIX_TIMESTAMP_FORMAT } from 'utils/formats';
import { MainLevee } from './types';
import { MainleveeResponse } from 'hooks/dossiers/useFetchDonneesMainlevee';
import { gradesAgent, typesRestitution } from 'utils/enumData';

export function convertMainLeveeToMainLeveeRequestDto(
  mainLevee: MainLevee,
): UpdateMainLeveeRequestDto {
  return {
    numProcesVerbal: mainLevee.numProcesVerbal || null,
    dateProcesVerbal:
      mainLevee.dateProcesVerbal?.format(UNIX_TIMESTAMP_FORMAT) || null,
    typeRestitution: mainLevee.typeRestitution
      ? typesRestitution[mainLevee.typeRestitution].requestKey
      : null,
    communeRedaction: mainLevee.communeRedaction || null,
    gradeAgent: mainLevee.gradeAgent
      ? gradesAgent[mainLevee.gradeAgent].requestKey
      : null,
    nomAgent: mainLevee.nomAgent || null,
    matriculeAgent: mainLevee.matriculeAgent || null,
    communeFonctionAgent: mainLevee.communeFonctionAgent || null,
    immatriculation: mainLevee.immatriculation || null,
    idCorrelationGenreSimplifie: mainLevee.idCorrelationGenreSimplifie || null,
    idMarque: mainLevee.idMarque || null,
    nomProprietaire: mainLevee.nomProprietaire || null,
    prenomProprietaire: mainLevee.prenomProprietaire || null,
    rsProprietaire: mainLevee.rsProprietaire || null,
    lieuAdresseProprietaire: mainLevee.lieuAdresseProprietaire || null,
    codePostalProprietaire: mainLevee.codePostalProprietaire || null,
    communeAdresseProprietaire: mainLevee.communeAdresseProprietaire || null,
    idCorrelationFourriere: mainLevee.idCorrelationFourriere || null,
    interdictionCirculer: mainLevee.interdictionCirculer || null,
    motifInterdictionCirculer: mainLevee.motifInterdictionCirculer || null,
    dateDebutPermissionProvisoire:
      mainLevee.dateDebutPermissionProvisoire?.format(UNIX_TIMESTAMP_FORMAT) ||
      null,
    dateFinPermissionProvisoire:
      mainLevee.dateFinPermissionProvisoire?.format(UNIX_TIMESTAMP_FORMAT) ||
      null,
  };
}

export async function validateMainLeveeRule(
  dossierId: string,
  controller: ForceOrdreControllerApi,
  values: UpdateMainLeveeRequestDto,
  field: MainLeveeViolationDtoFormFieldEnum,
): Promise<void> {
  try {
    const response = await controller.validateUpdateMainLeveeFieldUsingPOST(
      field,
      dossierId,
      values,
    );
    if (response.mainLeveeViolationDtoList) {
      const fieldViolations = response.mainLeveeViolationDtoList.filter(
        violation => violation.formField === field,
      );

      if (fieldViolations.length > 0) {
        return Promise.reject(
          fieldViolations.map(violation => violation.message).join(', '),
        );
      }
    }
  } catch (e) {
    if (e instanceof Response) {
      return Promise.reject((await backAlertMessage(e)).description);
    }
    return Promise.reject(defaultErrorMessage);
  }
  return Promise.resolve();
}

export function useSubmitMainLevee(
  controller: ForceOrdreControllerApi,
): [
  DossierResponseDto | null,
  (mainLevee: MainLevee, dossierId: string) => Promise<DossierResponseDto>,
  (dossier: DossierResponseDto) => void,
  (errorResponse: Response) => void,
] {
  const behaviourOnError = useHandleBackErrors();
  const [updatedDossier, setUpdatedDossier] =
    useState<DossierResponseDto | null>(null);

  const submitMainLevee = useCallback(
    (mainLevee: MainLevee, dossierId: string): Promise<DossierResponseDto> => {
      const requestDto: UpdateMainLeveeRequestDto =
        convertMainLeveeToMainLeveeRequestDto(mainLevee);

      return controller.updateMainLeveeUsingPOST(dossierId, requestDto);
    },
    [controller],
  );

  const onSuccess = useCallback(
    (dossier: DossierResponseDto): void => {
      setUpdatedDossier(dossier);
    },
    [setUpdatedDossier],
  );

  const onError = useCallback(
    (errorResponse: Response): void => {
      behaviourOnError(errorResponse);
    },
    [behaviourOnError],
  );

  return [updatedDossier, submitMainLevee, onSuccess, onError];
}

export function buildDefaultMainLeveeValues(
  mainlevee: MainleveeResponse,
): MainLevee {
  return {
    numProcesVerbal: mainlevee?.procesVerbal.numero ?? undefined,
    dateProcesVerbal: mainlevee?.procesVerbal.date
      ? moment(mainlevee.procesVerbal.date)
      : undefined,
    gradeAgent: mainlevee?.agent.grade ?? undefined,
    matriculeAgent: mainlevee?.agent.matricule ?? undefined,
    nomAgent: mainlevee?.agent.nom ?? undefined,
    communeFonctionAgent: mainlevee?.agent.communeFonction ?? undefined,
    typeRestitution: mainlevee?.typeRestitution ?? undefined,
    idCorrelationGenreSimplifie:
      mainlevee?.vehicule.idCorrelationGenreSimplifie ?? undefined,
    immatriculation: mainlevee?.vehicule.immatriculation ?? undefined,
    idMarque: mainlevee?.vehicule.idMarque ?? undefined,
    nomProprietaire: mainlevee?.proprietaire.nom ?? undefined,
    prenomProprietaire: mainlevee?.proprietaire.prenom ?? undefined,
    rsProprietaire: mainlevee?.proprietaire.raisonSociale ?? undefined,
    lieuAdresseProprietaire: mainlevee?.proprietaire.adresse ?? undefined,
    communeAdresseProprietaire: mainlevee?.proprietaire.commune ?? undefined,
    codePostalProprietaire: mainlevee?.proprietaire.codePostal ?? undefined,
    idCorrelationFourriere: mainlevee?.idCorrelationFourriere ?? undefined,
    interdictionCirculer:
      mainlevee?.interdictionCirculer.hasInterdictionCirculer ?? undefined,
    dateDebutPermissionProvisoire: mainlevee?.interdictionCirculer
      .dateDebutPermissionProvisoire
      ? moment(mainlevee?.interdictionCirculer.dateDebutPermissionProvisoire)
      : undefined,
    dateFinPermissionProvisoire: mainlevee?.interdictionCirculer
      .dateFinPermissionProvisoire
      ? moment(mainlevee?.interdictionCirculer.dateFinPermissionProvisoire)
      : undefined,
    motifInterdictionCirculer:
      mainlevee?.interdictionCirculer.motifInterdictionCirculer ?? undefined,
    communeRedaction: mainlevee?.communeRedaction ?? undefined,
  };
}
