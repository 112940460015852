import React, { useEffect, useState } from 'react';
import { TableProps as AntdTableProps } from 'antd/lib/table';
import { SortableContainerProps } from 'react-sortable-hoc';

import {
  ColumnProps,
  FetchDataFunctionWithoutArgument,
  FetchDataResult,
} from '../types';
import { BasicTable } from 'components/WrappedComponents/Table';
import { DragHandle, SortableBody, SortableItem } from './utils';
import {
  DataTypeWithId,
  DraggableBodyRowProps,
  OnSortEndProps,
  SortableBodyProps,
} from './types';

import './DragSortableTable.less';

interface DragSortableTableProps<DataType extends DataTypeWithId> {
  // Table - general
  columns: ColumnProps<DataType>[];
  fetchData?: FetchDataFunctionWithoutArgument<DataType>;
  disabled?: boolean;
  buildItemKey: (item: DataType) => string | number;
  antdTableProps?: Partial<AntdTableProps<DataType>>;
  onDragEnd: (ordered: DataType[]) => void;
}

/**
 * Table component to display a large amount of data. This component provide a table that enable drag and drop sorting.
 *
 * @param columns - Paramters to represent columns used in this Table. Each column is
 *        associated with a single attribute of fetched items
 * @param fetchData - Function called to fetch data (either sync or async). This function is
 *        supposed to return a list of fetched object corresponding to those filters..
 * @param buildItemKey - Function to build a unique key for each item. Required when
 *        using item seection.
 * @param antdTableProps - To use other props defined by Antd library on Table component
 *        (/!\ may interfere with props already defined by this component).
 * @param onDragEnd - function to play after drag an element
 */
function DragSortableTable<DataType extends DataTypeWithId>({
  // Table - general
  columns,
  fetchData,
  //other
  disabled,
  buildItemKey,
  antdTableProps,
  onDragEnd,
}: DragSortableTableProps<DataType>): React.ReactElement {
  const [isLoadingData, setIsLoadingData] = useState<boolean>(true);
  const [fetchedData, setFetchedData] = useState<
    FetchDataResult<DataType> | undefined
  >(undefined);
  const [isOnError, setIsOnError] = useState<boolean>(false);

  // This variable is increase each time we want to re-fetch data
  const [triggerUpdate, setTriggerUpdate] = useState<number>(0);

  // When the parameters change, we recall the function to retrieve corresponding data
  useEffect(() => {
    let isOutdated = false;
    // Fetch new data
    const loadNewData = async function loadNewData(): Promise<void> {
      try {
        if (fetchData !== undefined) {
          setIsLoadingData(true);
          const data = await fetchData();
          if (!isOutdated) {
            setFetchedData(data);
            setIsOnError(false);
            setIsLoadingData(false);
          }
        }
      } catch (e) {
        if (!isOutdated) {
          setFetchedData({ data: [], total: 0 });
          setIsOnError(true);
          setIsLoadingData(false);
        }
      }
    };
    void loadNewData();
    return (): void => {
      isOutdated = true;
    };
  }, [triggerUpdate, fetchData]);

  if (disabled === true) {
    return (
      <>
        <BasicTable
          data={fetchedData !== undefined ? fetchedData.data : []}
          columnsProps={columns}
          isLoading={isLoadingData}
          reload={() => setTriggerUpdate(val => ++val)}
          isOnError={isOnError}
          antdTableProps={{
            ...antdTableProps,
            className: 'DragSortableTable',
            pagination: false,
          }}
        />
      </>
    );
  } else {
    // in order to be able to do the drag n drop, we add the DragHandle to the columns
    if (!columns.find(c => c.dataIndex === 'sort')) {
      columns = [
        {
          title: '',
          key: 'sort',
          width: 40,
          buildCellContent: () => <DragHandle />,
        },
        ...columns,
      ];
    }

    const onSortEnd = ({ oldIndex, newIndex }: OnSortEndProps) => {
      if (oldIndex !== newIndex && onDragEnd) {
        if (fetchedData?.data) {
          const newFetchedData = { ...fetchedData };
          const newData: DataType[] = [...newFetchedData.data];
          newData.splice(oldIndex, 1);
          newData.splice(newIndex, 0, newFetchedData.data[oldIndex]);
          newFetchedData.data = newData;
          setFetchedData(newFetchedData);
          onDragEnd(newFetchedData.data);
        }
      }
    };

    const DraggableContainer = (
      props: React.ComponentClass<
        SortableBodyProps & SortableContainerProps,
        DataType
      >,
    ) => (
      <SortableBody
        useDragHandle
        disableAutoscroll
        helperClass="RowDragging"
        onSortEnd={onSortEnd}
        {...props}
      />
    );

    const DraggableBodyRow = ({
      ...restProps
    }: DraggableBodyRowProps<DataType>) => {
      // function findIndex base on Table rowKey props and should always be a right array index
      const index = fetchedData?.data.findIndex((x: DataType) => {
        return x.id === restProps['data-row-key'];
      });
      return (
        <SortableItem index={index === undefined ? -1 : index} {...restProps} />
      );
    };

    return (
      <>
        <BasicTable
          data={fetchedData !== undefined ? fetchedData.data : []}
          columnsProps={columns}
          isLoading={isLoadingData}
          reload={() => setTriggerUpdate(val => ++val)}
          isOnError={isOnError}
          buildItemKey={buildItemKey}
          antdTableProps={{
            ...antdTableProps,
            className: 'DragSortableTable',
            rowKey: 'id',
            pagination: false,
            components: {
              body: {
                wrapper: DraggableContainer,
                row: DraggableBodyRow,
              },
            },
          }}
        />
      </>
    );
  }
}

export default DragSortableTable;
