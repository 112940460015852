import React from 'react';
import { Modal as AntdModal } from 'antd';
import { useLocation } from 'react-router-dom';
import {
  ComputedResponseDtoListActionsEnum,
  ComputedResponseDtoListAvailableFilesEnum,
} from 'lib_api/lib/api/gen';

import { useDossierContext } from 'hooks/dossiers/DossierContext';
import { useApi } from 'hooks/ApiStoreContext';
import { useAFFilterContext } from 'hooks/AFFilterStoreContext';
import { useDisplayActionsRequisesBanner } from 'hooks/useActionsRequises';
import { useHandleBackErrors } from 'hooks/utils/handleBackErrors';
import { ActionsRequisesLinkState } from 'utils/actionsRequisesUtils';
import CoordonneesProprietaireForm from 'components/FicheDescriptiveDisplay/CoordonneesProprietaire/CoordonneesProprietaireForm';
import { AvailableAction } from 'components/ActionAndFileWrapper/types';
import ActionAndFileWrapper from 'components/ActionAndFileWrapper/ActionAndFileWrapper';
import ModalProlongation from 'components/FicheDescriptiveDisplay/Prolongation/ModalProlongation';
import ClassementForm from '../Classement/ClassementForm';
import { extractNonnullDossierIdOrThrow } from 'utils/dossierUtils';
import { filterUndefinedOrNull } from 'utils';

const ActionsSuiviVehicule = (): React.ReactElement => {
  const behaviourOnError = useHandleBackErrors();
  const { state } = useLocation() as { state: ActionsRequisesLinkState };
  const [isClassementOpen, setIsClassmentOpen] = React.useState<boolean>(
    state?.openClassementManuel || false,
  );
  const [isProlongationOpen, setIsProlongationOpen] = React.useState<boolean>(
    state?.openProlongationMef || false,
  );
  const [isUpdateCoord, setIsUpdateCoord] = React.useState<boolean>(
    state?.openCoordonneesProprietaire || false,
  );

  const [dossier, setDossier] = useDossierContext();
  const [, , afActionsRequises] = useAFFilterContext();
  const dossierId = extractNonnullDossierIdOrThrow(dossier);

  // display the actions requises banner(s)
  useDisplayActionsRequisesBanner(dossier, afActionsRequises);

  const afController = useApi().AutoriteFourriereControllerApi;

  function mapComputedListActionsEnumToAvailableAction(
    actionEnum: ComputedResponseDtoListActionsEnum,
  ): AvailableAction | null {
    switch (actionEnum) {
      case ComputedResponseDtoListActionsEnum.CLASSER_MANUELLEMENT:
        return {
          title: 'Modifier les données techniques du véhicule',
          onClick: () => {
            setIsClassmentOpen(true);
          },
        };

      case ComputedResponseDtoListActionsEnum.PROLONGER_MEF:
        return {
          title: 'Prolonger la mise en fourrière administrative',
          onClick: () => {
            setIsProlongationOpen(true);
          },
        };

      case ComputedResponseDtoListActionsEnum.MODIFIER_COORDONNEES_PROPRIETAIRE:
        return {
          title: 'Modifier les coordonnées du propriétaire',
          onClick: () => {
            setIsUpdateCoord(true);
          },
        };

      case ComputedResponseDtoListActionsEnum.TRAITE_EN_FACTURATION:
        return {
          title: 'Marquer comme traité en facturation',
          onClick: () => {
            afController
              .submitTraitementFacturationUsingPOST(
                extractNonnullDossierIdOrThrow(dossier),
                true,
              )
              .then(setDossier)
              .catch(behaviourOnError);
          },
        };

      case ComputedResponseDtoListActionsEnum.NON_TRAITE_EN_FACTURATION:
        return {
          title: 'Marquer comme non traité en facturation',
          onClick: () => {
            afController
              .submitTraitementFacturationUsingPOST(
                extractNonnullDossierIdOrThrow(dossier),
                false,
              )
              .then(setDossier)
              .catch(behaviourOnError);
          },
        };

      default:
        return null;
    }
  }

  const filesList: ComputedResponseDtoListAvailableFilesEnum[] =
    dossier.computed?.listAvailableFiles ?? [];
  const actions: AvailableAction[] = filterUndefinedOrNull(
    dossier.computed?.listActions?.map(action => {
      return mapComputedListActionsEnumToAvailableAction(action);
    }) || [],
  );

  return (
    <>
      <ActionAndFileWrapper
        filesList={filesList}
        actions={actions}
        dossierId={dossierId}
      />

      {/* classement form modal */}
      <AntdModal
        visible={isClassementOpen}
        onCancel={() => {
          setIsClassmentOpen(false);
        }}
        footer={null}
        closable={false}
        destroyOnClose={true}
        width={680}
        maskClosable={false}
      >
        <ClassementForm
          close={() => {
            setIsClassmentOpen(false);
          }}
        />
      </AntdModal>

      {/* prolonger mise en fourriere modal */}
      <ModalProlongation
        visible={isProlongationOpen}
        close={() => {
          setIsProlongationOpen(false);
        }}
      />

      {/* update coordonnées propriétaire modal */}
      <AntdModal
        visible={isUpdateCoord}
        onCancel={() => setIsUpdateCoord(false)}
        footer={null}
        closable={false}
        destroyOnClose={true}
        width={680}
        maskClosable={false}
      >
        <CoordonneesProprietaireForm
          controller={afController}
          close={() => setIsUpdateCoord(false)}
          editable={true}
        />
      </AntdModal>
    </>
  );
};

export default ActionsSuiviVehicule;
