import { HermesActiveSolutionDto } from 'lib_api/lib/api/gen';
import { NavbarItem } from 'components/NavBar/types';
import { profileEnumMapping } from 'utils/enumData';
import { useEnvironnement } from '../../hooks/EnvironementStoreContext';

const getImportRoute = (path: string) => {
  return {
    label: 'Import refus / impossibilités de vente',
    link: `${path}/domaine/import`,
  };
};

export const faqViewPath = `${profileEnumMapping['ADMIN_FONCTIONNEL'].homePath}/referentiels/dashboard/faq/view`;

export const faqDomainPath = `${profileEnumMapping['ADMIN_FONCTIONNEL'].homePath}/referentiels/dashboard/faq-domaine`;

export const faqPath = `${profileEnumMapping['ADMIN_FONCTIONNEL'].homePath}/referentiels/dashboard/faq`;

/**
 * Build import/export route items only if the import/export Hermes bypass solution is enabled
 * @param path default admin path
 * @param activeSolution
 */
const buildRemiseAuDomaineRoutes = (
  path: string,
  activeSolution?: HermesActiveSolutionDto,
): NavbarItem[] => {
  const availableRoutes = [
    ...(activeSolution?.contournementImportEnabled
      ? [getImportRoute(path)]
      : []),
  ];

  if (availableRoutes.length === 0) {
    return [];
  }

  return [
    {
      label: 'Remise au domaine',
      dropdown: availableRoutes,
    },
  ];
};

export const useAdminNavbarContent = (
  path: string,
  activeSolution?: HermesActiveSolutionDto,
): NavbarItem[] => {
  const [env] = useEnvironnement();

  return [
    {
      label: 'Tableau de bord',
      link: `${path}`,
    },
    {
      label: 'Suivi du workflow',
      link: `${env.CAMUNDA_URL}${env.CAMUNDA_COCKPIT_PATH}`,
    },
    {
      label: 'Gestion des référentiels',
      dropdown: [
        {
          label: 'Import des référentiels',
          link: `${path}/referentiels/import`,
        },
        {
          label: 'Export des référentiels',
          link: `${path}/referentiels/export`,
        },
        {
          label: 'Gestion des manuels',
          link: `${path}/referentiels/manuels`,
        },
        {
          label: 'Gestion du modèle de fiche descriptive',
          link: `${path}/referentiels/fiche-descriptive`,
        },
        {
          label: 'Gestion du bandeau information',
          link: `${path}/referentiels/information-message`,
        },
        {
          label: "Gestion des unités des forces de l'ordre",
          link: `${path}/referentiels/dashboard/unite-fo`,
        },
        {
          label: 'Gestion des fourrières',
          link: `${path}/referentiels/dashboard/fourriere`,
        },
        {
          label: 'Gestion des autorités de fourrière',
          link: `${path}/referentiels/dashboard/autorite-fourriere`,
        },
        {
          label: "Gestion des types d'autorité de fourrière",
          link: `${path}/referentiels/dashboard/type-autorite-fourriere`,
        },
        {
          label: 'Gestion des marques',
          link: `${path}/referentiels/dashboard/marque`,
        },
        {
          label: 'Gestion des motifs de MEF',
          link: `${path}/referentiels/dashboard/motif-mef`,
        },
        {
          label: 'Gestion des motifs de sortie',
          link: `${path}/referentiels/dashboard/motif-sortie`,
        },
        {
          label: 'Gestion des centres VHU',
          link: `${path}/referentiels/dashboard/centre-VHU`,
        },
        {
          label: 'Gestion de la FAQ',
          link: `${path}/referentiels/dashboard/faq-domaine`,
        },
      ],
    },
    ...buildRemiseAuDomaineRoutes(path, activeSolution),
    {
      label: 'Comptes des utilisateurs',
      link: `${path}/comptes`,
    },
    {
      label: 'Gestion du classement',
      link: `${path}/classement`,
    },
    {
      label: 'Suivi des flux',
      dropdown: [
        {
          label:
            'Hermès : Flux de récupération des statuts de vente des dossiers',
          link: `${path}/fluxs/vente-dossiers`,
        },
      ],
    },
  ];
};
