import React, { useState } from 'react';

interface UseModalReturnType {
  visible: boolean;
  open: () => void;
  close: () => void;
}

export const useModal = (): UseModalReturnType => {
  const [visible, setVisible] = useState(false);
  const open = () => setVisible(true);
  const close = () => setVisible(false);

  return {
    visible,
    open,
    close,
  };
};
