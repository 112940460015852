import React, { useEffect } from 'react';
import { Modal as AntdModal } from 'antd';
import { DossierResponseDto } from 'lib_api/lib/api/gen';

import ComparaisonSivFd from './ComparaisonSivFd/ComparaisonSivFd';
import ControlerDonneesVehiculeForm from './ControlerDonneesVehiculeForm/ControlerDonneesVehiculeForm';
import { useBoolean } from 'utils/genericUtils';
import { extractNonnullDossierIdOrThrow } from 'utils/dossierUtils';
import { useApi } from 'hooks/ApiStoreContext';
import { useDossierContext } from 'hooks/dossiers/DossierContext';
import { useFetchDonneesAControler } from 'hooks/dossiers/useFetchDonneesAControler';
import { useSubmitControleDonneesVehicule } from './utils';
import AsyncComponent from '../../AsyncComponent/AsyncComponent';

import './ModalControlerDonneesVehicule.less';

interface ModalControlerDonneesVehiculeProps {
  close: () => void;
}

const ModalControlerDonneesVehicule = ({
  close,
}: ModalControlerDonneesVehiculeProps): React.ReactElement => {
  const {
    value: formIsOpen,
    setIsTrue: openForm,
    setIsFalse: closeForm,
  } = useBoolean();

  const foController = useApi().ForceOrdreControllerApi;

  const [dossier, setDossier] = useDossierContext();
  const dossierId = extractNonnullDossierIdOrThrow(dossier);
  const {
    object: donneesAControler,
    fetch,
    inProgress: fetchInProgress,
    errorOccured: fetchErrorOccured,
  } = useFetchDonneesAControler(dossierId);

  const [submit, submitInProgress] = useSubmitControleDonneesVehicule(
    foController,
    dossierId,
    (updatedDossier: DossierResponseDto) => {
      setDossier(updatedDossier);
      return fetch();
    },
    close,
  );

  useEffect(fetch, [fetch]);
  return (
    <AsyncComponent
      reload={fetch}
      inProgress={fetchInProgress}
      render={() => {
        return !formIsOpen ? (
          <ComparaisonSivFd
            onClose={close}
            openUpdateForm={openForm}
            donneesAControler={donneesAControler}
            submitInProgress={submitInProgress}
            submit={submit}
          />
        ) : (
          <ControlerDonneesVehiculeForm
            onCancel={closeForm}
            donneesAControler={donneesAControler}
            submitInProgress={submitInProgress}
            submit={submit}
            dossierId={dossierId}
          />
        );
      }}
    />
  );
};

export default ModalControlerDonneesVehicule;
