import { createStore } from './utils/createStore';

import { UserDto } from 'lib_api/lib/api/gen';

const userStore = createStore<UserDto>();

export const UserStoreProvider = userStore.Provider;
export const useUserStore: () => UserDto = () => {
  const user = userStore.useStore()[0];
  return user;
};
