import { ExportDashboardUsingGETQueryParams } from 'lib_api/lib/api/gen';

import { TableSort } from 'components/WrappedComponents/Table/types';
import { useHandleBackErrors } from 'hooks/utils/handleBackErrors';
import { useDownloadableFilesContext } from 'hooks/DownloadableFilesContext';
import { useApi } from 'hooks/ApiStoreContext';
import { notifyFileGenerationInProgress } from 'components/DownloadableFiles/utils';
import { baseOptionTypeArrayToStringArray } from 'components/BaseForm/utils';
import { useBoolean } from 'utils/genericUtils';
import {
  AdminFilters,
  Filters,
  SortOptions,
} from './useSearchDossierSummaries';

export interface UseExportDahboardGenericResult<
  Filters = undefined,
  Sorters extends string | undefined = undefined,
> {
  exportDashboard: (sort: TableSort<Sorters>, filter: Partial<Filters>) => void;
  inProgress: boolean;
}

interface UseExportDahboardGenericProps<
  Filters = undefined,
  Sorters extends string | undefined = undefined,
> {
  buildRequest: (
    sorters: TableSort<Sorters>,
    filters: Partial<Filters>,
  ) => ExportDashboardUsingGETQueryParams;
}

function useExportDahboardGeneric<
  Filters = undefined,
  Sorters extends string | undefined = undefined,
>({
  buildRequest,
}: UseExportDahboardGenericProps<
  Filters,
  Sorters
>): UseExportDahboardGenericResult<Filters, Sorters> {
  const controllerApi = useApi().ExportControllerApi;
  const behaviourOnError = useHandleBackErrors();
  const { getDownloadableFiles } = useDownloadableFilesContext();
  const {
    value: inProgress,
    setIsTrue: setIsInProgress,
    setIsFalse: setIsEnded,
  } = useBoolean();

  const exportDashboard = (
    sorters: TableSort<Sorters>,
    filters: Partial<Filters>,
  ) => {
    setIsInProgress();
    controllerApi
      .exportDashboardUsingGET(buildRequest(sorters, filters))
      .then(() => {
        getDownloadableFiles();
        notifyFileGenerationInProgress();
      })
      .catch((errorResponse: Response) => {
        behaviourOnError(errorResponse);
      })
      .finally(setIsEnded);
  };

  return {
    exportDashboard,
    inProgress,
  };
}

/**
 * Hook to export dossier based on given filters.
 */
export function useExportDashboard(): UseExportDahboardGenericResult<
  Filters,
  SortOptions
> {
  return useExportDahboardGeneric<Filters, SortOptions>({
    buildRequest: (sorters, filters) => {
      return {
        dossierCriteriaDto: {
          ...filters,
          dateFromBonEnlevement: filters.dateRangeBonEnlevement?.[0]?.toDate(), // date bon enlevement (début)
          dateFromMiseAJour: filters.dateRangeMiseAJour?.[0]?.toDate(), // date mise à jour (début)
          dateFromMiseEnFourriere:
            filters.dateRangeMiseEnFourriere?.[0]?.toDate(), // date mise en fourrière (début)
          dateFromOrdreDestruction:
            filters.dateRangeOrdreDestruction?.[0]?.toDate(), // date ordre destruction (début)
          dateFromSortieDefinitive:
            filters.dateRangeSortieDefinitive?.[0]?.toDate(), // date sortie (début)
          dateToBonEnlevement: filters.dateRangeBonEnlevement?.[1]?.toDate(), // date bon enlevement (début)
          dateToMiseAJour: filters.dateRangeMiseAJour?.[1]?.toDate(), // date mise à jour (fin)
          dateToMiseEnFourriere:
            filters.dateRangeMiseEnFourriere?.[1]?.toDate(), // date mise en fourrière (fin)
          dateToOrdreDestruction:
            filters.dateRangeOrdreDestruction?.[1]?.toDate(), // date ordre de destruction (fin)
          dateToSortieDefinitive:
            filters.dateRangeSortieDefinitive?.[1]?.toDate(), // date sortie (fin)
          idUniteFOList: filters.listUniteFo,
          idFourriereList: filters.idFourriereList,
          idMotifSortieList: filters.idMotifSortieList,
          idNatureBrancheList: filters.idNatureBrancheList,
        },
        dossierSortDto: {
          alertesIsAsc: sorters?.ACTIONS_REQUISES,
          classementIsAsc: sorters?.CLASSEMENT,
          couleurIsAsc: sorters?.COULEUR,
          dateBonEnlevementIsAsc: sorters?.DATE_BON_ENLEVEMENT,
          dateMiseAJourIsAsc: sorters?.DATE_MISE_A_JOUR,
          dateMiseFourriereIsAsc: sorters?.DATE_MISE_FOURRIERE,
          dateOrdreDestructionIsAsc: sorters?.DATE_ORDRE_DESTRUCTION,
          dateSortieDefinitiveIsAsc: sorters?.DATE_SORTIE_DEFINITIVE,
          fourriereIsAsc: sorters?.FOURRIERE,
          immatriculationIsAsc: sorters?.IMMATRICULATION,
          marqueIsAsc: sorters?.MARQUE,
          modeleIsAsc: sorters?.MODELE,
          natureMiseEnFourriereIsAsc: sorters?.NATURE_MISE_EN_FOURRIERE,
          statutIsAsc: sorters?.STATUT,
        },
      };
    },
  });
}

/**
 * Hook to export dossier based on given filters.
 */
export function useExportAdminDashboard(): UseExportDahboardGenericResult<
  AdminFilters,
  SortOptions
> {
  return useExportDahboardGeneric<AdminFilters, SortOptions>({
    buildRequest: (sorters, filters) => {
      return {
        dossierCriteriaDto: {
          ...filters,
          dateFromBonEnlevement: filters.dateRangeBonEnlevement?.[0]?.toDate(), // date bon enlevement (début)
          dateFromMiseAJour: filters.dateRangeMiseAJour?.[0]?.toDate(), // date mise à jour (début)
          dateFromMiseEnFourriere:
            filters.dateRangeMiseEnFourriere?.[0]?.toDate(), // date mise en fourrière (début)
          dateFromOrdreDestruction:
            filters.dateRangeOrdreDestruction?.[0]?.toDate(), // date ordre destruction (début)
          dateFromSortieDefinitive:
            filters.dateRangeSortieDefinitive?.[0]?.toDate(), // date sortie (début)
          dateToBonEnlevement: filters.dateRangeBonEnlevement?.[1]?.toDate(), // date bon enlevement (début)
          dateToMiseAJour: filters.dateRangeMiseAJour?.[1]?.toDate(), // date mise à jour (fin)
          dateToMiseEnFourriere:
            filters.dateRangeMiseEnFourriere?.[1]?.toDate(), // date mise en fourrière (fin)
          dateToOrdreDestruction:
            filters.dateRangeOrdreDestruction?.[1]?.toDate(), // date ordre de destruction (fin)
          dateToSortieDefinitive:
            filters.dateRangeSortieDefinitive?.[1]?.toDate(), // date sortie (fin)
          idUniteFOList: baseOptionTypeArrayToStringArray(filters.listUniteFo),
          idFourriereList: baseOptionTypeArrayToStringArray(
            filters.idFourriereList,
          ),
          idMotifSortieList: filters.idMotifSortieList,
          idNatureBrancheList: filters.idNatureBrancheList,
        },
        dossierSortDto: {
          alertesIsAsc: sorters?.ACTIONS_REQUISES,
          classementIsAsc: sorters?.CLASSEMENT,
          couleurIsAsc: sorters?.COULEUR,
          dateBonEnlevementIsAsc: sorters?.DATE_BON_ENLEVEMENT,
          dateMiseAJourIsAsc: sorters?.DATE_MISE_A_JOUR,
          dateMiseFourriereIsAsc: sorters?.DATE_MISE_FOURRIERE,
          dateOrdreDestructionIsAsc: sorters?.DATE_ORDRE_DESTRUCTION,
          dateSortieDefinitiveIsAsc: sorters?.DATE_SORTIE_DEFINITIVE,
          fourriereIsAsc: sorters?.FOURRIERE,
          immatriculationIsAsc: sorters?.IMMATRICULATION,
          marqueIsAsc: sorters?.MARQUE,
          modeleIsAsc: sorters?.MODELE,
          natureMiseEnFourriereIsAsc: sorters?.NATURE_MISE_EN_FOURRIERE,
          statutIsAsc: sorters?.STATUT,
        },
      };
    },
  });
}
