import React, { useState, useEffect } from 'react';
import JWTDecode from 'jwt-decode';

import { UserDto, ErrorDtoCodeEnum } from 'lib_api/lib/api/gen';
import { buildAuthApi, AuthApi } from 'lib_api/lib/api/buildAuthApi';

import { ApiProvider } from '../hooks/ApiStoreContext';
import { isErrorBodyAnErrorDto } from '../hooks/utils/backAlertMessage';
import { useEnvironnement } from '../hooks/EnvironementStoreContext';
import { UserStoreProvider } from '../hooks/UserStoreContext';

import WithIDP from './WithIDP';
import { useAuthentication } from '../hooks/utils/authentication';

interface Props {
  children: React.ReactFragment;
}

interface JWTBody {
  sub: string;
  iat: number;
  exp: number;
}

/**
 * Modified fetch including a bearer token
 */
function fetchWithBearerToken(
  withBearer: string,
): (url: RequestInfo, options?: RequestInit | undefined) => Promise<Response> {
  return async (url: RequestInfo, options?: RequestInit): Promise<Response> => {
    let optionsWithToken = options || {};
    const previousHeader = (options && options.headers) || {};
    optionsWithToken = {
      ...options,
      headers: {
        ...previousHeader,
        Authorization: `Bearer ${withBearer}`,
      },
    };
    return fetch(url, optionsWithToken);
  };
}

/**
 * Modified fetch using bearer token and dealing with expired token
 *
 * @param withBearer - token to put in header
 * @param manageExpiredToken - callback to manage expired token, returns up-to-date token
 * @param manageExpiredSession - callback to manage reauthentication
 */
function authenticatedFetch(
  withBearer: string,
  manageExpiredToken: () => Promise<string | null>,
  manageExpiredSession: () => void,
): (url: RequestInfo, options?: RequestInit | undefined) => Promise<Response> {
  return async (url: RequestInfo, options?: RequestInit): Promise<Response> => {
    let res = await fetchWithBearerToken(withBearer)(url, options);

    // Access granted, proces normally
    if (res.status !== 401) {
      return res;
    }

    // Access denied...

    // ... because of expired token, try again after token has been refreshed
    const body = await res.json();
    if (
      isErrorBodyAnErrorDto(body) &&
      body.code === ErrorDtoCodeEnum.API_EXPIRED_TOKEN
    ) {
      const newBearer = await manageExpiredToken();
      if (newBearer !== null) {
        res = await fetchWithBearerToken(newBearer)(url, options);
        if (res.status !== 401) {
          return res;
        }
      }
    }

    // ... because of something else, or refreshing token failed, we need to re-authenticate on idp
    manageExpiredSession();
    return res;
  };
}

export function decodeToken(token: string): UserDto | undefined {
  return JSON.parse(JWTDecode<JWTBody>(token).sub);
}

export function Authenticator({ children }: Props): JSX.Element {
  const [user, setUser] = useState<UserDto>();
  const [connectToIDP, setConnectToIDP] = useState(true);
  const [token, setToken] = useState<string>();
  const [refreshToken, setRefreshToken] = useState<string>();
  const [api, setApi] = useState<AuthApi>();
  const [env] = useEnvironnement();
  const { refresh } = useAuthentication();

  useEffect(() => {
    // When session is expired, we need to authenticate again through IDP portal
    function manageExpiredSession() {
      setConnectToIDP(true);
    }

    // When token is expired, use refresh token route to get a new token
    async function manageExpiredToken(): Promise<string | null> {
      if (refreshToken === undefined) {
        return null;
      }
      const jwtDto = await refresh(refreshToken);
      if (jwtDto === null) {
        return null;
      }
      setToken(jwtDto.accessToken);
      setRefreshToken(jwtDto.refreshToken || undefined);
      return jwtDto.accessToken;
    }

    if (token) {
      setConnectToIDP(false);
      setApi(
        buildAuthApi(
          authenticatedFetch(token, manageExpiredToken, manageExpiredSession),
          env.BACKEND_URL,
        ),
      );
      setUser(decodeToken(token));
    }
  }, [token, refreshToken, refresh, env]);

  return (
    <WithIDP
      setToken={setToken}
      setRefreshToken={setRefreshToken}
      shouldConnect={connectToIDP}
    >
      {!!token && !!api && !!user && (
        <UserStoreProvider value={user}>
          <ApiProvider value={api}>{children}</ApiProvider>
        </UserStoreProvider>
      )}
    </WithIDP>
  );
}
