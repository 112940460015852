import React from 'react';
import {
  MarqueDto,
  MarqueDtoCategorieEnum,
  MarqueDtoTypeEnum,
  MarqueViolationDtoFormFieldEnum,
} from 'lib_api/lib/api/gen';
import { useAsyncSearchMarque } from 'hooks/search/async/useAsyncSearchMarque';
import { useFetchMarque } from 'hooks/referentiels/useFetchMarque';
import BaseSelect from 'components/BaseForm/Select/BaseSelect';
import BaseSelectWithSearch from 'components/BaseForm/Select/BaseSelectWithSearch';
import { FormInputProps } from 'components/BaseForm/types';
import { VALUE_NON_REFERENCE } from 'utils/global';
import { categorieMarqueMapping, typeMarqueMapping } from 'utils/enumData';
import ModalEdition from '../../Modals/ModalEdition/ModalEdition';
import {
  buildDefaultUpdateFormValues,
  useSubmitMarque,
  useValidateMarque,
} from '../utils';
import { MarqueDtoFormValues } from '../types';

interface ModalDetailsProps {
  marque: MarqueDto;
  visible: boolean;
  close: () => void;
  refreshDashboard: () => void;
}

function ModalModification({
  marque,
  visible,
  close,
  refreshDashboard,
}: ModalDetailsProps): React.ReactElement {
  const idCorrelation = marque.idCorrelation || '';

  const marqueExacte = useFetchMarque(marque.marqueExacteId);
  const initialValues: MarqueDtoFormValues = buildDefaultUpdateFormValues(
    marque,
    marqueExacte,
  );

  // list of options of select
  const categoriesOptions = [
    {
      value: MarqueDtoCategorieEnum.A_CATEGORISER,
      displayValue:
        categorieMarqueMapping[MarqueDtoCategorieEnum.A_CATEGORISER],
    },
    {
      value: MarqueDtoCategorieEnum.NON_VALORISABLE,
      displayValue:
        categorieMarqueMapping[MarqueDtoCategorieEnum.NON_VALORISABLE],
    },
    {
      value: MarqueDtoCategorieEnum.PREMIUM,
      displayValue: categorieMarqueMapping[MarqueDtoCategorieEnum.PREMIUM],
    },
    {
      value: '',
      displayValue: 'Sans catégorie',
    },
  ];

  // list of options of select of types
  const typesOptions = [
    {
      value: MarqueDtoTypeEnum.INCONNUE,
      displayValue: typeMarqueMapping[MarqueDtoTypeEnum.INCONNUE],
    },
    {
      value: MarqueDtoTypeEnum.APPROXIMATIVE,
      displayValue: typeMarqueMapping[MarqueDtoTypeEnum.APPROXIMATIVE],
    },
    {
      value: MarqueDtoTypeEnum.EXACTE,
      displayValue: typeMarqueMapping[MarqueDtoTypeEnum.EXACTE],
    },
  ];

  const validate = useValidateMarque(idCorrelation);

  const { submit, inProgress } = useSubmitMarque(
    idCorrelation,
    refreshDashboard,
    close,
  );

  const baseInputs: Array<
    FormInputProps<MarqueDtoFormValues, MarqueViolationDtoFormFieldEnum>
  > = [
    {
      name: 'categorie',
      label: 'Catégorie',
      render: () => {
        return (
          <BaseSelect
            options={categoriesOptions}
            getOptionLabel={option => option.displayValue}
            getOptionValue={option => option.value}
          />
        );
      },
    },
    {
      field: MarqueViolationDtoFormFieldEnum.TYPE,
      name: 'type',
      label: 'Type',
      render: () => {
        return (
          <BaseSelect
            options={typesOptions}
            getOptionLabel={option => option.displayValue}
            getOptionValue={option => option.value}
          />
        );
      },
    },
    {
      field: MarqueViolationDtoFormFieldEnum.MARQUE_EXACTE,
      label: 'Marque exacte associée',
      name: 'marqueExacte',
      dependencies: ['type'],
      required: true,
      displayItem: (values: MarqueDtoFormValues) => {
        return values.type === MarqueDtoTypeEnum.APPROXIMATIVE;
      },
      render: () => {
        return (
          <BaseSelectWithSearch
            useSearchEntities={useAsyncSearchMarque(
              ['EXACTE'],
              marqueExacte ?? undefined,
            )}
            getOptionLabel={(marque: MarqueDto | null) => marque?.libelle}
            getOptionValue={(marque: MarqueDto | null) =>
              marque?.id ?? VALUE_NON_REFERENCE
            }
          />
        );
      },
    },
  ];

  return (
    <ModalEdition
      close={close}
      visible={visible}
      initials={initialValues}
      isSubmitting={inProgress}
      submit={submit}
      title={'Modification de la marque'}
      validate={validate}
      inputs={baseInputs}
    />
  );
}

export default ModalModification;
