import { useCallback, useState } from 'react';
import {
  ControleDonneesVehiculeRequestDto,
  ControleDonneesVehiculeViolationDtoFormFieldEnum,
  DonneesAControlerResponseDto,
  DossierResponseDto,
  ForceOrdreControllerApi,
  MarqueDto,
} from 'lib_api/lib/api/gen';

import { LABEL_MARQUE_NON_REFERENCEE, VALUE_NON_REFERENCE } from 'utils/global';
import { backAlertMessage } from 'hooks/utils/backAlertMessage';
import { useValidateField } from 'hooks/utils/handleValidationRequest';
import { useApi } from 'hooks/ApiStoreContext';
import { BaseOptionType } from '../../BaseForm/Select/BaseSelect';
import { DossierModificationViolationsDto } from 'lib_api/lib/api/gen/api';

export interface ControleDonneesVehiculeFormValues {
  plusieursPlaques: boolean | null;
  newGenre: string | null;
  newMarque: BaseOptionType<MarqueDto>;
  newMarqueNonReferencee: boolean | null;
  newModele: string | null;
  newModeleNonReference: boolean | null;
  newPaysEtranger: boolean | null;
  newPlaqueImmatriculation: string | null;
  newPlaqueImmatriculationVisible: string | null;
  newSansPlaque: boolean | null;
  newVin: string | null;
}

export function buildInitialValues(
  donneesAControler: DonneesAControlerResponseDto | null,
): ControleDonneesVehiculeFormValues {
  return {
    plusieursPlaques:
      donneesAControler?.donneesFd?.plaqueImmatriculationVisible !== null,
    newMarque: {
      option: donneesAControler?.donneesFd?.marque || undefined,
      label:
        donneesAControler?.donneesFd?.marque?.libelle ||
        LABEL_MARQUE_NON_REFERENCEE,
    },
    newMarqueNonReferencee:
      donneesAControler?.donneesFd?.marqueNonReferencee || false,
    newModele:
      donneesAControler?.donneesFd?.modele?.idCorrelation ||
      VALUE_NON_REFERENCE,
    newModeleNonReference:
      donneesAControler?.donneesFd?.modeleNonReference || false,
    newGenre:
      donneesAControler?.donneesFd?.genreSimplifie?.idCorrelation || null,
    newPaysEtranger: donneesAControler?.donneesFd?.paysEtranger || false,
    newSansPlaque: donneesAControler?.donneesFd?.sansPlaque || false,
    newPlaqueImmatriculation:
      donneesAControler?.donneesFd?.plaqueImmatriculation || null,
    newPlaqueImmatriculationVisible:
      donneesAControler?.donneesFd?.plaqueImmatriculationVisible || null,
    newVin: donneesAControler?.donneesFd?.vin || null,
  };
}

export function buildRequestValues(
  values: ControleDonneesVehiculeFormValues,
): ControleDonneesVehiculeRequestDto {
  return {
    headers: null,
    newMarque:
      values.newMarque?.value === VALUE_NON_REFERENCE
        ? null
        : values.newMarque?.option?.idCorrelation || null,
    newMarqueNonReferencee: values.newMarque?.value === VALUE_NON_REFERENCE,
    newModele:
      values.newModele === VALUE_NON_REFERENCE
        ? null
        : values.newModele || null,
    newModeleNonReference: values.newModele === VALUE_NON_REFERENCE,
    newGenre: values.newGenre,
    newPaysEtranger: values.newPaysEtranger,
    newSansPlaque: values.newSansPlaque,
    newPlaqueImmatriculation: values.newPlaqueImmatriculation,
    newPlaqueImmatriculationVisible: values.newPlaqueImmatriculationVisible,
    newVin: values.newVin,
  };
}

export function useValidateControleDonneesVehiculeField(
  dossierId: string,
): (
  field: ControleDonneesVehiculeViolationDtoFormFieldEnum,
  formValues: ControleDonneesVehiculeFormValues,
) => Promise<void> {
  const { ForceOrdreControllerApi: foController } = useApi();

  const sendRequest = useCallback(
    (
      field: ControleDonneesVehiculeViolationDtoFormFieldEnum,
      request: ControleDonneesVehiculeRequestDto,
    ) => {
      return foController.validateFieldControleDonneesVehiculeUsingPOST(
        field,
        dossierId,
        request,
      );
    },
    [dossierId, foController],
  );

  const extractor = useCallback(
    (response: DossierModificationViolationsDto) => {
      return response.controleDonneesVehiculeViolationDtoList ?? [];
    },
    [],
  );

  return useValidateField(buildRequestValues, sendRequest, extractor).validate;
}

export function useSubmitControleDonneesVehicule(
  controller: ForceOrdreControllerApi,
  dossierId: string,
  setDossier: (dossier: DossierResponseDto) => void,
  closeModal: () => void,
): [(values: ControleDonneesVehiculeFormValues) => void, boolean] {
  const [inProgress, setInProgress] = useState<boolean>(false);
  return [
    (values: ControleDonneesVehiculeFormValues) => {
      setInProgress(true);
      return controller
        .updateControleDonneesVehiculeUsingPOST(
          dossierId,
          buildRequestValues(values),
        )
        .then(setDossier)
        .then(closeModal)
        .catch((error: Response) => {
          void backAlertMessage(error);
        })
        .finally(() => {
          setInProgress(false);
        });
    },
    inProgress,
  ];
}
