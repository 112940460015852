import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from 'react-sortable-hoc';
import { MenuOutlined } from '@ant-design/icons';
import React from 'react';
import { SortableBodyProps } from './types';

export const DragHandle = SortableHandle(() => (
  <MenuOutlined style={{ cursor: 'grab', color: '#999', marginLeft: 10 }} />
));

export const SortableItem = SortableElement(
  (
    props: React.DetailedHTMLProps<
      React.HTMLAttributes<HTMLTableRowElement>,
      HTMLTableRowElement
    >,
  ) => <tr {...props} />,
);
export const SortableBody = SortableContainer((props: SortableBodyProps) => (
  <tbody {...props} />
));
