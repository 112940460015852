import { ReactJsonViewProps, ThemeObject } from 'react-json-view';

const customTheme: ThemeObject = {
  base00: '#ffffff', // Default background
  base01: '#ffffff', // Lighter Background (Used for status bars, line number and folding marks)
  base02: '#ffffff', // Selection Background
  base03: '#75715e', // Comments, Invisibles, Line Highlighting
  base04: '#a59f85', // Dark Foreground (Used for status bars)
  base05: '#272822', // Default Foreground, Caret, Delimiters, Operators
  base06: '#272822', // Light Foreground (Not often used)
  base07: '#272822', // Light Background (Not often used)
  base08: '#f92672', // Variables, XML Tags, Markup Link Text, Markup Lists, Diff Deleted
  base09: '#50A14F', // Integers, Boolean, Constants, XML Attributes, Markup Link Url
  base0A: '#0184BB', // Classes, Markup Bold, Search Text Background
  base0B: '#a6e22e', // Strings, Inherited Class, Markup Code, Diff Inserted
  base0C: '#a1efe4', // Support, Regular Expressions, Escape Characters, Markup Quotes
  base0D: '#0e9bb6', // Functions, Methods, Attribute IDs, Headings
  base0E: '#8c52f6', // Keywords, Storage, Selector, Markup Italic, Diff Changed
  base0F: '#cc6633', // Deprecated, Opening/Closing Embedded Language Tags, e.g. <?php ?>
};

const customStyle: Omit<ReactJsonViewProps, 'src'> = {
  style: {},
  name: null,
  theme: customTheme,
  iconStyle: 'square',
  displayDataTypes: false,
  quotesOnKeys: false,
  collapsed: 2,
};

export default customStyle;
