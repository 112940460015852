import React, { useState, Dispatch, SetStateAction } from 'react';

import OpenIdConnectIDP from './OpenIdConnectIDP';
import { UnauthorizedPage } from 'components/Error';
import UnauthentifiedHeader from 'components/Header/UnauthentifiedHeader';
import { ErrorDto } from 'lib_api/lib/api/gen';

interface Props {
  setToken: Dispatch<SetStateAction<string | undefined>>;
  setRefreshToken: Dispatch<SetStateAction<string | undefined>>;
  children?: React.ReactFragment;
  shouldConnect: boolean;
}

/**
 * When authentication is need, use the proper IDP.
 */
export default function WithIDP({
  shouldConnect,
  setToken,
  setRefreshToken,
  children,
}: Props): JSX.Element {
  const [authError, setAuthError] = useState<ErrorDto>();

  // Authentication already failed once
  if (authError) {
    return (
      <>
        <UnauthentifiedHeader className={'Header'} />
        <UnauthorizedPage error={authError} />
      </>
    );
  }

  // Need to authenticate
  if (shouldConnect) {
    return (
      <OpenIdConnectIDP
        setToken={setToken}
        setRefreshToken={setRefreshToken}
        setAuthError={setAuthError}
      />
    );
  }

  // Already authenticated
  // Keycloak automatically appends session state and code to the url, not allowing any refresh
  const code = new URLSearchParams(window.location.search).get('code');
  const sessionState = new URLSearchParams(window.location.search).get(
    'session_state',
  );

  // Remove both code and session_state from the url
  if (code || sessionState) {
    window.history.replaceState(
      null,
      '',
      window.location.origin + window.location.pathname,
    );
  }

  return <>{children}</>;
}
