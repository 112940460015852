import React from 'react';
import classNames from 'classnames';

import JsonViewer from 'components/JsonViewer/JsonViewer';
import { DossierActivityItem } from 'hooks/dossierAdmin/useFetchDetailedEvents';

import './DetailedEventsDetails.less';

interface DetailedEventsDetailsProps {
  events: DossierActivityItem[];
}

export default function DetailedEventsDetails({
  events,
}: DetailedEventsDetailsProps): React.ReactElement {
  return (
    <>
      {events.map((item, index) => {
        return (
          <div
            key={index + 1}
            ref={item.ref}
            className={classNames('DetailsContainer', {
              incident: item.isIncident,
            })}
          >
            <h3>{item.detailsTitle ?? item.label}</h3>
            <JsonViewer
              src={item.content}
              style={{ background: 'transparent' }}
            />
          </div>
        );
      })}
    </>
  );
}
