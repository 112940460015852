import React from 'react';
import { useForm } from 'antd/lib/form/Form';
import { Form as AntdForm, Typography as AntdTypo } from 'antd';

import { isEmptyObject, useBoolean } from 'utils/genericUtils';
import WarningMessage from '../WarningMessage/WarningMessage';
import FormItem from './FormItem';
import { BaseFormProps } from './types';
import {
  atLeastOneErrorInForm,
  buildCancelButton,
  buildSubmitButton,
  setFieldValue,
} from './utils';
import BaseFormProvider from './BaseFormContext';

import './BaseForm.less';

export default function BaseForm<FormValues, FormField>({
  inputs,
  initialValues,
  validateField,
  onSubmit,
  onChange,
  isSubmitting,
  onCancel,
  renderButtonAction,
  warningMessage,
  submitText,
  labelCol,
  wrapperCol,
  subForms,
}: BaseFormProps<FormValues, FormField>): React.ReactElement {
  // Declare Antd form state
  const [form] = useForm<FormValues>();
  const formValues = isEmptyObject(form.getFieldsValue())
    ? initialValues
    : form.getFieldsValue();

  const {
    value: isValidatingBeforeSubmit,
    setIsTrue: startIsValidating,
    setIsFalse: stopIsValidating,
  } = useBoolean();

  return (
    <BaseFormProvider value={form}>
      <AntdForm
        form={form}
        initialValues={initialValues}
        onFinish={values => {
          stopIsValidating();
          onSubmit(values);
        }}
        onFinishFailed={() => {
          stopIsValidating();
        }}
        onValuesChange={(
          changedValue: Partial<FormValues>,
          allValues: FormValues,
        ) => {
          // onChange permit to make changes on form each time some value is changed.
          // It returns new values to store in form values state
          if (onChange) {
            Object.entries(onChange(changedValue, allValues)).forEach(entry => {
              setFieldValue(form, entry[0], entry[1]);
            });
          }
        }}
      >
        {(): React.ReactElement => {
          const hasError = atLeastOneErrorInForm(form);
          const SubmitButton = buildSubmitButton({
            isValidatingBeforeSubmit: isValidatingBeforeSubmit,
            startIsValidating: startIsValidating,
            isSubmitting: isSubmitting ?? false,
            text: submitText ?? 'Enregistrer',
            hasError: hasError,
          });
          const CancelButton = onCancel
            ? buildCancelButton(onCancel, form)
            : undefined;

          return (
            <>
              {subForms &&
                subForms.map((subForm, index) => {
                  return (
                    <div key={index}>
                      <AntdTypo.Title level={4}>{subForm.title}</AntdTypo.Title>
                      {subForm.inputs.map((input, inputIndex) => (
                        <FormItem
                          {...input}
                          key={inputIndex}
                          formValues={formValues}
                          labelCol={labelCol}
                          wrapperCol={wrapperCol}
                          validateField={validateField}
                        />
                      ))}
                    </div>
                  );
                })}
              {inputs &&
                inputs.map((input, inputIndex) => (
                  <FormItem
                    {...input}
                    key={inputIndex}
                    formValues={formValues}
                    labelCol={labelCol}
                    wrapperCol={wrapperCol}
                    validateField={validateField}
                  />
                ))}
              {warningMessage && (
                <WarningMessage
                  title={warningMessage.title}
                  detail={warningMessage.detail}
                  style={warningMessage.style}
                />
              )}
              {renderButtonAction ? (
                renderButtonAction(SubmitButton, CancelButton)
              ) : (
                <div className="BaseFormActions">
                  <div className="Action">{CancelButton}</div>
                  <div className="Action">{SubmitButton}</div>
                </div>
              )}
            </>
          );
        }}
      </AntdForm>
    </BaseFormProvider>
  );
}
