import { useEffect, useState } from 'react';
import { HermesActiveSolutionDto } from 'lib_api/lib/api/gen';

import { backAlertMessage } from 'hooks/utils/backAlertMessage';
import { useApi } from 'hooks/ApiStoreContext';

function useActiveHermesSolution(): HermesActiveSolutionDto | undefined {
  const controller = useApi().AdminFonctionnelControllerApi;
  const [activeSolution, setActiveSolution] = useState<
    HermesActiveSolutionDto | undefined
  >();

  useEffect(() => {
    controller
      .getHermesActiveSolutionUsingGET()
      .then(solution => setActiveSolution(solution))
      .catch(err => backAlertMessage(err, false));
  }, [controller]);

  return activeSolution;
}

export default useActiveHermesSolution;
