import React from 'react';

import {
  CreateUserRequestDtoProfileEnum,
  InformationMessageRequestDtoUserProfileListEnum,
  InformationMessageViolationDtoFormFieldEnum,
} from 'lib_api/lib/api/gen';
import { Select } from 'components/WrappedComponents';

import { FieldProps } from 'components/WrappedComponents/Form/FormWrapper/type';
import { CSSUtils } from 'utils/CSSUtils';
import { FormPlaceholders } from 'types/enums/FormPlaceholders';
import { useApi } from 'hooks/ApiStoreContext';
import { InformationMessageFormValues } from 'types/InformationMessage/InformationMessage';
import {
  generateRequestDtoFromFormData,
  validateInformationMessageRequest,
} from '../utils';

function SelectProfile({
  values,
}: FieldProps<InformationMessageFormValues>): React.ReactElement {
  const controller = useApi().ReferentielControllerApi;
  const key: keyof InformationMessageFormValues = 'profiles';

  // list of options of select
  const selectItems = [
    {
      value: InformationMessageRequestDtoUserProfileListEnum.GARDIEN_FOURRIERE,
      displayValue: 'Gardien de fourrière',
    },
    {
      value: CreateUserRequestDtoProfileEnum.AUTORITE_FOURRIERE,
      displayValue: 'Autorité de fourrière',
    },
    {
      value: CreateUserRequestDtoProfileEnum.FORCE_DE_L_ORDRE,
      displayValue: "Force de l'ordre",
    },
  ];

  return (
    <Select
      wrapperProps={{
        values: values,
        fieldKey: key,
        itemProps: {
          label: 'Utilisateurs concernés',
          rules: [
            {
              required: true,
              validator: async (_rule, value): Promise<void> => {
                const requestObject = generateRequestDtoFromFormData({
                  ...values,
                  profiles: value || undefined,
                });

                return await validateInformationMessageRequest(
                  controller,
                  requestObject,
                  InformationMessageViolationDtoFormFieldEnum.PROFILES,
                );
              },
            },
          ],
          labelCol: { span: CSSUtils.LlargeWrapperCol },
          wrapperCol: { span: CSSUtils.largeWrapperCol },
        },
      }}
      optionProps={selectItems}
      selectProps={{
        placeholder: FormPlaceholders.Select,
        mode: 'multiple',
      }}
    />
  );
}

export default SelectProfile;
