import React from 'react';
import { Checkbox as AntdCheckbox } from 'antd';
import { CheckboxGroupProps as AntdCheckboxProps } from 'antd/lib/checkbox';
import { useFormActionsContext } from '../../FormWrapper/FormActionsContext';
import FieldWrapper from '../FieldWrapper';
import { WrappedProps } from '../types';

interface Props<T> {
  wrapperProps: WrappedProps<T>;
  checkboxGroupProps: AntdCheckboxProps;
}

function WrappedCheckboxGroup<T>({
  wrapperProps,
  checkboxGroupProps,
}: Props<T>): React.ReactElement {
  const checkboxGroup = React.useMemo(() => {
    return <AntdCheckbox.Group {...checkboxGroupProps} />;
  }, [checkboxGroupProps]);

  wrapperProps.itemProps.validateTrigger = 'onChange';

  const formActions = useFormActionsContext();
  const fieldKey = wrapperProps.fieldKey;
  const array: unknown[] = formActions.getFieldValue(fieldKey) || [];

  return (
    <FieldWrapper
      ConsultationElement={
        array.length === 0 ? (
          <span className={'EmptyValue'}>Aucune valeur sélectionnée</span>
        ) : (
          array.join(', ')
        )
      }
      {...wrapperProps}
      WrappedInput={checkboxGroup}
    />
  );
}

export default WrappedCheckboxGroup;
