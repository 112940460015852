import React from 'react';
import ReactJson, { ReactJsonViewProps } from 'react-json-view';

import customStyle from './customStyle';

import './JsonViewer.less';

const JsonViewer = (props: ReactJsonViewProps): React.ReactElement => {
  // By default the custom style is used, but it can be overridden by using the props
  const propsWithDefaultStyle = {
    ...customStyle,
    ...props,
  };

  return (
    <div className="JsonViewer">
      <ReactJson {...propsWithDefaultStyle} />
    </div>
  );
};

export default JsonViewer;
