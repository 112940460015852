import React from 'react';
import { Typography } from 'antd';

import useMaintenance from 'hooks/useMaintenance';
import NotFound from 'assets/images/svg/icons/not_found.svg';

import './Maintenance.less';

const { Title } = Typography;

interface MaintenanceProps {
  children?: React.ReactNode;
}

const Maintenance: React.FC = (props: MaintenanceProps): React.ReactElement => {
  const { isActuallyInMaintenance, endDateFromNow } = useMaintenance();

  if (isActuallyInMaintenance) {
    return (
      <div className="Maintenance">
        <Title>Le site est en cours de maintenance</Title>
        <p>
          Nous mettons tout en oeuvre pour rendre le site de nouveau disponible.
          <br />
          {endDateFromNow && (
            <>
              Le SI devrait être de nouveau opérationnel {endDateFromNow}.
              <br />
            </>
          )}
          Nous tenons à nous excuser pour le dérangement.
        </p>
        <img className="NotFoundImg" src={NotFound} alt="Not found" />
      </div>
    );
  }

  return <>{props.children}</>;
};

export default Maintenance;
