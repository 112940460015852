import {
  AutoriteFourriereAutocompleteDepartementResultDto,
  AutoriteFourriereAutocompleteDepartementResultDtoFieldEnum,
  ReferentielControllerApi,
} from 'lib_api/lib/api/gen';

function searchDepartementAutoriteFourriere(
  controller: ReferentielControllerApi,
  field: AutoriteFourriereAutocompleteDepartementResultDtoFieldEnum,
  searchedStr: string,
): Promise<AutoriteFourriereAutocompleteDepartementResultDto> {
  return controller.autoCompleteAutoriteFourriereFieldUsingGET(field, {
    searchedStr,
  });
}

export default searchDepartementAutoriteFourriere;
