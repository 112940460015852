import { useEffect, useMemo, useState } from 'react';
import {
  ActionsRequisesDtoActionsRequisesEnum,
  ComputedResponseDtoListActionsRequisesEnum,
  DossierResponseDto,
} from 'lib_api/lib/api/gen';
import { useApi } from './ApiStoreContext';
import { backAlertMessage } from './utils/backAlertMessage';
import { useDossierActionsRequisesContext } from './DossierActionsRequisesContext';

export const actionsRequisesMapping: {
  [key in ActionsRequisesDtoActionsRequisesEnum]: string;
} = {
  [ActionsRequisesDtoActionsRequisesEnum.CLASSER_MANUELLEMENT]:
    'Données du classement à compléter',
  [ActionsRequisesDtoActionsRequisesEnum.MAIN_LEVEE]: 'Saisir la main levée',
  [ActionsRequisesDtoActionsRequisesEnum.MODIFIER_COORDONNEES_PROPRIETAIRE]:
    'Coordonnées du propriétaire à compléter',
  [ActionsRequisesDtoActionsRequisesEnum.PROLONGER_MEF]:
    'Prolonger la mise en fourrière administrative',
  [ActionsRequisesDtoActionsRequisesEnum.RENSEIGNER_SORTIE_VEHICULE]:
    'Renseigner la sortie',
  [ActionsRequisesDtoActionsRequisesEnum.SAISIE_NOTIFICATION_MANUELLE]:
    'Notification à éditer',
  [ActionsRequisesDtoActionsRequisesEnum.SAISIE_VHU]:
    "Générer le bon d'enlèvement pour destruction",
  [ActionsRequisesDtoActionsRequisesEnum.TRAITE_EN_FACTURATION]:
    'Marquer comme traité en facturation',
  [ActionsRequisesDtoActionsRequisesEnum.NON_TRAITE_EN_FACTURATION]:
    'Marquer comme non traité en facturation',
  [ActionsRequisesDtoActionsRequisesEnum.ANNULATION_SORTIE_VEHICULE]:
    'Annuler la sortie',
  [ActionsRequisesDtoActionsRequisesEnum.EXPORT_DONNEES_DOSSIER]:
    'Exporter les données du dossier',
  [ActionsRequisesDtoActionsRequisesEnum.SUPPRESSION_DOSSIER]:
    'Supprimer le dossier',
  [ActionsRequisesDtoActionsRequisesEnum.UPDATE_ENTITES_RATTACHEMENT]:
    'Modifier les entités de rattachement',
  [ActionsRequisesDtoActionsRequisesEnum.MODIFICATION_SORTIE_VEHICULE]:
    'Modifier les données de sortie du véhicule',
  [ActionsRequisesDtoActionsRequisesEnum.UPDATE_DONNEES_VEHICULE]:
    "Modifier les données du véhicule ou de l'infraction",
  [ActionsRequisesDtoActionsRequisesEnum.UPDATE_CLASSEMENT]:
    'Modifier le classement',
  [ActionsRequisesDtoActionsRequisesEnum.RENSEIGNER_STATUT_RETOUR_NOTIFICATION_MANUELLE]:
    'Renseigner le statut de retour de la notification manuelle',
  [ActionsRequisesDtoActionsRequisesEnum.CORRIGER_DONNEES_NOTIFICATION_MANUELLE]:
    'Modifier les informations de notification manuelle',
  [ActionsRequisesDtoActionsRequisesEnum.ENREGISTRER_ENTREE]:
    'Finaliser l’enregistrement de l’entrée',
  [ActionsRequisesDtoActionsRequisesEnum.CONTROLER_DONNEES_VEHICULE]:
    'Contrôler les données du véhicule',
};

export function useActionsRequises():
  | ActionsRequisesDtoActionsRequisesEnum[]
  | undefined {
  const [actionsRequises, setActionsRequises] =
    useState<ActionsRequisesDtoActionsRequisesEnum[]>();
  const { DossierControllerApi } = useApi();

  useEffect(() => {
    DossierControllerApi.getActionsRequisesByProfileUsingGET()
      .then(({ actionsRequises }) => {
        setActionsRequises(actionsRequises);
      })
      .catch(backAlertMessage);
  }, [DossierControllerApi]);

  return actionsRequises;
}

export function useDisplayActionsRequisesBanner(
  dossier: DossierResponseDto,
  profileActionsRequises?: ActionsRequisesDtoActionsRequisesEnum[],
): void {
  const { setDossierActionsRequises } = useDossierActionsRequisesContext();
  const currentActionsRequises = useMemo<
    ComputedResponseDtoListActionsRequisesEnum[] | undefined
  >(
    () =>
      dossier.computed?.listActionsRequises?.filter(actionRequise =>
        profileActionsRequises?.includes(
          ActionsRequisesDtoActionsRequisesEnum[actionRequise],
        ),
      ),
    [dossier, profileActionsRequises],
  );

  // display the actions requises banner(s)
  useEffect(() => {
    if (!!currentActionsRequises && currentActionsRequises.length > 0) {
      setDossierActionsRequises(currentActionsRequises);
    }
    return () => setDossierActionsRequises([]);
  }, [setDossierActionsRequises, currentActionsRequises]);
}
