import { createContext, useContext, Dispatch, SetStateAction } from 'react';

import { DashboardAdminFonctionnelFilter } from '../pages/admin-fonctionnel/Dashboard/types';

interface AdminFonctionnelFilterContextContent {
  filter: Partial<DashboardAdminFonctionnelFilter>;
  setFilter: Dispatch<SetStateAction<Partial<DashboardAdminFonctionnelFilter>>>;
}

const AdminFonctionnelFilterContext =
  createContext<AdminFonctionnelFilterContextContent | null>(null);

export const useAdminFonctionnelFilterContext = (): [
  Partial<DashboardAdminFonctionnelFilter>,
  Dispatch<SetStateAction<Partial<DashboardAdminFonctionnelFilter>>>,
] => {
  const context = useContext(AdminFonctionnelFilterContext);
  if (context === null) {
    throw new Error('useFilterContext called outside Context.');
  }

  return [context.filter, context.setFilter];
};

const AdminFonctionnelFilterProvider = AdminFonctionnelFilterContext.Provider;

export default AdminFonctionnelFilterProvider;
