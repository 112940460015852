import React from 'react';
import { Checkbox as AntdCheckbox } from 'antd';

import {
  ControleDonneesVehiculeViolationDtoFormFieldEnum,
  DonneesAControlerResponseDto,
  MarqueDto,
} from 'lib_api/lib/api/gen';

import { useApi } from 'hooks/ApiStoreContext';
import { useAsyncSearchMarque } from 'hooks/search/async/useAsyncSearchMarque';
import { fetchGenresimplifie } from 'search/searchGenreSimplifie';
import SelectModeleByIdCorrelationMarque from './Fields/SelectModeleByIdCorrelationMarque';
import {
  LABEL_MARQUE_NON_REFERENCEE,
  LABEL_NON_RENSEIGNE,
  VALUE_NON_REFERENCE,
} from 'utils/global';
import {
  buildInitialValues,
  ControleDonneesVehiculeFormValues,
  useValidateControleDonneesVehiculeField,
} from '../utils';
import BaseForm from 'components/BaseForm/BaseForm';
import AsyncSelect from 'components/BaseForm/Select/AsyncSelect';
import BaseInput from 'components/BaseForm/BaseInput';
import BaseSelectWithSearch from 'components/BaseForm/Select/BaseSelectWithSearch';

import './ControlerDonnesVehiculeForm.less';

interface ControlerDonneesVehiculeFormProps {
  onCancel: () => void;
  donneesAControler: DonneesAControlerResponseDto | null;
  dossierId: string;
  submit: (values: ControleDonneesVehiculeFormValues) => void;
  submitInProgress: boolean;
}

const ControlerDonneesVehiculeForm: React.FC<
  ControlerDonneesVehiculeFormProps
> = ({
  dossierId,
  onCancel,
  donneesAControler,
  submit,
  submitInProgress,
}: ControlerDonneesVehiculeFormProps) => {
  const {
    ReferentielControllerApi: refController,
    ForceOrdreControllerApi: foController,
  } = useApi();

  const validateField = useValidateControleDonneesVehiculeField(dossierId);

  const warningMessage = {
    title: "Conséquence de l'enregistrement",
    detail:
      'Si des modifications ont été effectuées, la fiche descriptive sera mise à jour avec ces nouvelles données. Dans le cas d’une modification de l’immatriculation / identification ou du VIN, une nouvelle consultation du SIV sera réalisée pour que le SI Fourrières récupère les données effectives du véhicule (données techniques et coordonnées du propriétaire).',
    style: { maxWidth: '100%' },
  };

  return (
    <BaseForm
      initialValues={buildInitialValues(donneesAControler)}
      validateField={validateField}
      onChange={(changed, all) => {
        return {
          ...all,
          newModele: changed.newMarque ? null : all.newModele,
          newPlaqueImmatriculation:
            all.newSansPlaque === true ? null : all.newPlaqueImmatriculation,
        };
      }}
      inputs={[
        {
          name: 'plusieursPlaques',
          label: "Renseigner plusieurs plaques d'immatriculation",
          valuePropName: 'checked',
          render: () => {
            return <AntdCheckbox />;
          },
        },
        {
          field:
            ControleDonneesVehiculeViolationDtoFormFieldEnum.NEW_SANS_PLAQUE,
          name: 'newSansPlaque',
          label: 'Sans plaque',
          dependencies: ['newPlaqueImmatriculation'],
          valuePropName: 'checked',
          render: () => {
            return <AntdCheckbox />;
          },
        },
        {
          field:
            ControleDonneesVehiculeViolationDtoFormFieldEnum.NEW_PAYS_ETRANGER,
          name: 'newPaysEtranger',
          label: 'Pays étranger',
          dependencies: ['newSansPlaque', 'newPlaqueImmatriculation'],
          valuePropName: 'checked',
          render: () => {
            return <AntdCheckbox />;
          },
        },
        {
          field: ControleDonneesVehiculeViolationDtoFormFieldEnum.NEW_GENRE,
          required: true,
          name: 'newGenre',
          label: 'Genre',
          dependencies: ['newSansPlaque', 'newPlaqueImmatriculation'],
          render: () => {
            return (
              <AsyncSelect
                fetchOptions={() => {
                  return fetchGenresimplifie(refController);
                }}
                getOptionValue={genre => genre.idCorrelation}
                getOptionLabel={genre => genre.libelle}
                showSearch={true}
              />
            );
          },
        },
        {
          field:
            ControleDonneesVehiculeViolationDtoFormFieldEnum.NEW_PLAQUE_VISIBLE,
          name: 'newPlaqueImmatriculationVisible',
          label: "Faux numéro d'identification ou d'immatriculation (visible)",
          dependencies: ['plusieursPlaques'],
          required: true,
          displayItem: (values: ControleDonneesVehiculeFormValues) => {
            return values.plusieursPlaques === true;
          },
          normalize: (value: string) => value.toUpperCase(),
        },
        {
          field: ControleDonneesVehiculeViolationDtoFormFieldEnum.NEW_PLAQUE,
          name: 'newPlaqueImmatriculation',
          label: "Numéro d'identification ou d'immatriculation",
          dependencies: ['newSansPlaque', 'newPaysEtranger', 'newGenre'],
          normalize: (value: string) => value.toUpperCase(),
          required: (values: ControleDonneesVehiculeFormValues) => {
            return values.newSansPlaque !== true;
          },
          disabled: (values: ControleDonneesVehiculeFormValues) => {
            return values.newSansPlaque === true;
          },
          extra: (
            <div className="helpText">
              SIV :&nbsp;
              {donneesAControler?.donneesSiv?.plaqueImmatriculation ||
                LABEL_NON_RENSEIGNE}
            </div>
          ),
          render: ({ disabled }) => {
            return <BaseInput disabled={disabled} />;
          },
        },
        {
          field: ControleDonneesVehiculeViolationDtoFormFieldEnum.NEW_VIN,
          name: 'newVin',
          label: 'Numéro VIN',
          extra: (
            <div className="helpText">
              SIV : {donneesAControler?.donneesSiv?.vin || LABEL_NON_RENSEIGNE}
            </div>
          ),
        },
        {
          field: ControleDonneesVehiculeViolationDtoFormFieldEnum.NEW_MARQUE,
          required: true,
          name: 'newMarque',
          label: 'Marque',
          render: () => {
            return (
              <BaseSelectWithSearch
                useSearchEntities={useAsyncSearchMarque(['EXACTE', 'INCONNUE'])}
                getOptionValue={(marque: MarqueDto | null) =>
                  marque ? marque.idCorrelation || '' : VALUE_NON_REFERENCE
                }
                getOptionLabel={(marque: MarqueDto | null) =>
                  marque ? marque.libelle : LABEL_MARQUE_NON_REFERENCEE
                }
                additionalOptions={[null]}
              />
            );
          },
          extra: (
            <div className="helpText">
              SIV :&nbsp;
              {donneesAControler?.donneesSiv?.marque?.libelle ||
                LABEL_NON_RENSEIGNE}
            </div>
          ),
        },
        {
          label: 'Modèle',
          name: 'newModele',
          field: ControleDonneesVehiculeViolationDtoFormFieldEnum.NEW_MODELE,
          required: true,
          render: () => {
            return (
              <SelectModeleByIdCorrelationMarque idMarqueField="newMarque" />
            );
          },
          extra: (
            <div className="helpText">
              SIV :&nbsp;
              {donneesAControler?.donneesSiv?.modele?.libelle ||
                LABEL_NON_RENSEIGNE}
            </div>
          ),
        },
      ]}
      warningMessage={warningMessage}
      renderButtonAction={(Submit, Cancel) => {
        return (
          <div className="controlerDonnesVehiculeFormBtns">
            <div className="btnWrapper">{Cancel}</div>
            <div className="btnWrapper">{Submit}</div>
          </div>
        );
      }}
      onSubmit={submit}
      onCancel={onCancel}
      isSubmitting={submitInProgress}
    />
  );
};

export default ControlerDonneesVehiculeForm;
