import React from 'react';
import { ColProps, Empty, Spin as AntdSpin } from 'antd';

import { Select } from 'components/WrappedComponents/index';

import { CSSUtils } from 'utils/CSSUtils';
import { SearchResult } from 'types/searchResult';
import { FormPlaceholders } from 'types/enums/FormPlaceholders';

type SelectEntiteProps<FormValues, FormField, FieldValue> = {
  label: string;
  values: FormValues;
  defaultValue?: FieldValue;
  formKey: keyof FormValues & string;
  fieldsToValidateOnChange?: (keyof FormValues & string)[];
  field: FormField;
  validateField: (
    key: keyof FormValues,
    field: FormField,
    value: unknown,
  ) => Promise<void>;
  labelCol?: ColProps;
  wrapperCol?: ColProps;
  useSearchEntities: (defaultValue?: FieldValue) => SearchResult;
};

function SelectWithSearch<FormValues, FormField, FieldValue>(
  props: SelectEntiteProps<FormValues, FormField, FieldValue>,
): React.ReactElement {
  const {
    values,
    defaultValue,
    label,
    formKey,
    fieldsToValidateOnChange,
    field,
    validateField,
    useSearchEntities,
    labelCol,
    wrapperCol,
  } = props;
  const { result, inProgress, search } = useSearchEntities(defaultValue);

  return (
    <Select
      wrapperProps={{
        values: values,
        fieldKey: formKey,
        fieldsToValidateOnChange: fieldsToValidateOnChange,
        itemProps: {
          label: label,
          rules: [
            {
              required: true,
              validator: async (_rule, value): Promise<void> => {
                return await validateField(formKey, field, value);
              },
            },
          ],
          labelCol: labelCol ? labelCol : { span: CSSUtils.defaultLabelCol },
          wrapperCol: wrapperCol
            ? wrapperCol
            : { span: CSSUtils.LlargeWrapperCol },
        },
      }}
      optionProps={result}
      selectProps={{
        placeholder: FormPlaceholders.Select,
        dropdownMatchSelectWidth: 350,
        onSearch: search,
        notFoundContent: inProgress ? <AntdSpin size="small" /> : <Empty />,
      }}
    />
  );
}

export default SelectWithSearch;
